import React from 'react';
import './Map.scss';
import GoogleMapStructure from './GoogleMapStructure';

const MapPage = () => {

  return(
    <div className='map-container'>
      <h1>
        Map
      </h1>
      <div className='map'>
        <GoogleMapStructure/>
      </div>
    </div>
  )
};

export default MapPage;
