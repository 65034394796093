import React from 'react';
import './Yearbook.scss';
import Tooltip from '@material-ui/core/Tooltip';
interface Props {
  image: any;
  size: string;
}

const YearbookItem = (props: Props) => {
  const image = props.image;
  const size = props.size;

  return (
    <>
    {size !== 'max' && size !== 'medium' &&
    <img alt="large student" className="feed-image" src={"https://images.graduatetogetheryearbook.com/graduatetogether/image/upload/t_GT%20Web/v1589396769/" + image.image_id + ".jpg"} />
    }
    {size === 'medium' &&
      <img alt="medium student" className="feed-image" src={"https://images.graduatetogetheryearbook.com/graduatetogether/image/upload/t_GT_web_zoom_2/v1589396769/" + image.image_id + ".jpg"} />
    }
    {size === 'max' &&
      <img alt="small student" className="feed-image" src={"https://images.graduatetogetheryearbook.com/graduatetogether/image/upload/t_GT_web_zoom_1/v1589396769/" + image.image_id + ".jpg"} />
    }
    <div className="feed-text">
      <h3>{image.first_name} {image.last_name.charAt(0)}.</h3>
      <Tooltip title={image.school_name.replace(/&amp;/g, '&') + "," + image.state}>
      <p>{image.school_name.replace(/&amp;/g, '&')}, {image.state}</p>
      </Tooltip>
    </div>
    </>
  );
}

export default YearbookItem;