import React from 'react';
import './Footer.scss';
import insideout from '../../assets/inside-out.png';
import xq from '../../assets/xq-logo.svg';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-container">
        <div className="footer-content">
          <div className="logo">
            <a rel="noopener noreferrer" target="_blank" href="https://www.insideoutproject.net/en"><img src={insideout} className="inside-out-logo" alt="inside out logo" /></a>
            <a rel="noopener noreferrer" target="_blank" href="https://xqsuperschool.org/ "><img src={xq} className="inside-out-logo" alt="inside out logo" /></a>
          </div>
          <div className="right-content">
            <div className="menu-options">
              <Link to="/about">ABOUT</Link>
              <Link to="/terms">TERMS OF USE</Link>
              <Link to="/privacy">PRIVACY POLICY</Link>
            </div>
            <div className="copyright">
              <p>© Inside Out Project. All Rights Reserved.</p>
            </div>
            <div className="build-logo">
              <div className="logo">
                <a rel="noopener noreferrer" target="_blank" href="https://www.insideoutproject.net/en"><img src={insideout} className="inside-out-logo" alt="inside out logo" /></a>
                <a rel="noopener noreferrer" target="_blank" href="https://xqsuperschool.org/ "><img src={xq} className="inside-out-logo" alt="inside out logo" /></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
