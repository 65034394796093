import React from 'react';
import './Home.scss';
import snapchat from '../../assets/snapchat1.png';
import gt from '../../assets/gt-2020.png';
import { Link } from 'react-router-dom';
import ImageCarousel from '../ImageCarousel/ImageCarousel';

const Home = () => {
  return (
    <div className="home-container">
      <div className="headline">
        <div className="headline-content">
          <h1><span>A Yearbook honoring <br></br>the High school class of 2020</span></h1>
        </div>
      </div>

    <ImageCarousel />

      <div className="description">
        <div className="description-content">
          <p>Now’s your chance to be in the largest yearbook, ever! </p>
          <p>In honor of the High School Class of 2020, the Inside Out Project is inviting all graduating high school seniors 
            across the country to be featured in the Graduate Together Yearbook, celebrating the class of 2020!</p>
          <p>Follow the steps below to participate:</p>
        </div>
      </div>

      <div className="directions">
        <div className="directions-content">
          <div className="directions-box">
            <div className="directions-container">
              <div className="step">
                <h3 className="step-number">1</h3>
                <h3 className="step-name">
                  GET THE INSIDE OUT <br/>
                  GRADUATE TOGETHER LENS
                </h3>
              </div>
              <div className="step-directions">
                <p>Your photo must be taken using the Inside Out Graduate lens from Snapchat.</p>
                <p>
                  <a rel="noopener noreferrer" target="_blank" href="https://www.snapchat.com/unlock/?type=SNAPCODE&uuid=ee85aa5cade749bca9d796f12f1f3eef&metadata=01">
                    CLICK HERE
                  </a>
                  {' '}
                  to get the lens, or Snap the code below to unlock
                </p>
                <div className="snapchat">
                  <p>SNAP TO UNLOCK</p>
                  <img className="snapchat-icon" src={snapchat} alt="snapchat logo" />
                  <a rel="noopener noreferrer" target="_blank" href="https://www.snapchat.com/download">
                    <div className='snapchat-btn'>
                      DOWNLOAD SNAPCHAT
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="directions-box">
            <div className="directions-container">
              <div className="step">
                <h3 className="step-number">2</h3>
                <h3 className="step-name">
                  TAKE <br/>
                  YOUR YEARBOOK <br/>
                  PHOTO
                </h3>
              </div>
              <div className="step-directions step-two">
                <p>Take your photo using the Graduate Together lens and save it to your device.</p>
                <b>Important Guidelines:</b>
                <ul>
                  <li>No Group photos—just you!</li>
                  <li>Capture full face (see examples on this page)</li>
                  <li>Make sure your face is well lit</li>
                  <li>No sunglasses, masks or other similar items</li>
                  <li>Be expressive and have fun!</li>
                  <li>
                    <b>REMEMBER: </b>
                    Only photos taken with the Inside Out Graduate Together lens can be accepted!
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="directions-box">
            <div className="directions-container">
              <div className="step">
                <h3 className="step-number">3</h3>
                <h3 className="step-name">
                  SUBMIT YOUR YEARBOOK PHOTO AND WRITE YOUR MESSAGE
                </h3>
              </div>
              <div className="step-directions step-three">
                <p>
                  Tell us about you and your high school. Upload your photo, and write your message to the High School Class Of 2020.
                </p>
                <Link to="/form" className="upload-button-link">
                  <div className="upload-button">
                    START YOUR SUBMISSION
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="hashtag">
        <div className="hashtag-content">
          <a rel="noopener noreferrer" target="_blank" href="https://www.facebook.com/hashtag/graduatetogetheryearbook?__eep__=6&epa=HASHTAG">
            <h3>#GraduateTogetherYearbook</h3>
          </a>
        </div>
      </div>
      <div className="launch">
        <div className="launch-content">
          <div className="graduate-image">
          <a rel="noopener noreferrer" target="_blank" href="https://graduatetogether2020.com/ "><img src={gt} alt="graduate together 2020" /></a>

          </div>
          <div className="graduate-text">
            <p><b>Graduate Together: America Honors The Class Of 2020 </b></p>
            <p><b>Saturday, May 16th  8pm ET/PT  7pm CT</b></p>
            <p>This one-hour primetime special airs on ABC, CBS, FOX, and NBC, and will be streaming on multiple online platforms.
              <b> <a rel="noopener noreferrer" target="_blank" href="https://graduatetogether2020.com/">Learn More </a></b></p>
          </div>
        </div>
      </div>
      <div className="print">
        <div className="print-content">
          <h3 className="print-headline">RECEIVE YOUR YEARBOOK PHOTO PRINTED POSTER-SIZE</h3>
          <div className="print-image">
            <img src="https://images.graduatetogetheryearbook.com/graduatetogether/image/upload/c_scale,w_620,q_100/e_unsharp_mask:75/e_grayscale/e_contrast:30/v1588986362/webassets/print-image.aa1e9a24.jpg" alt="brick wall with posters" />
          </div>
          <div className="print-text">
            <h3 className="print-headline">RECEIVE YOUR YEARBOOK PHOTO PRINTED POSTER-SIZE</h3>
            <p>
              The Inside Out Project is offering the unique opportunity to receive a large scale poster of your 
              submitted Yearbook Photo, to be displayed at your school or a public location.
            </p>
            <p>
              <b>From each US State, five schools with the best submissions will receive poster-sized prints of 
              their Graduate Together Yearbook Photos.</b>
            </p>
            <a rel="noopener noreferrer" target="_blank" href="https://www.facebook.com/hashtag/graduatetogetheryearbook?__eep__=6&epa=HASHTAG">
              <p className="print-hashtag">#GraduateTogetherYearbook</p>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
