import React, {useState, useEffect} from 'react';
import './Yearbook.scss';
import InfiniteScroll from 'react-infinite-scroller';
import YearbookItem from './YearbookItem';
import classNames from 'classnames';
import StudentDialog from './StudentDialog';
import HighSchoolSelect from './HighSchoolSelect/HighSchoolSelect';
import { useForm } from "react-hook-form";
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import arrowUp from '../../assets/arrow-up-white.svg';
import arrowDown from '../../assets/arrow-down.svg';
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

import twitter from '../../assets/twitter.svg';
import facebook from '../../assets/facebook.svg';
import twitterWhite from '../../assets/twitterWhite.svg';
import facebookWhite from '../../assets/facebookWhite.svg';

const Yearbook = ({ match }) => {
  const { control } = useForm({ mode: `onChange`}); // initialise the hook
  let [sizeFilter, setSizeFilter] = useState('max');
  let [states, setStates] = useState([]);
  let [selectedState, setSelectedState] = useState(null);
  let [cities, setCities] = useState([]);
  let [selectedCity, setSelectedCity] = useState(null);
  let [hasMore, setHasMore] = useState(false);
  let [resetSchool, setResetSchool] = useState(false);
  let [next, setNext] = useState('');
  let [shareUrl, setShareUrl] = useState('');
  let [selectedSchool, setSelectedSchool] = useState(null);
  let [submissionCount, setSubmissionCount] = useState('0');
  let [images, setImages] = useState([]);
  const params = match.params;

  const changeSizeFilter = (size) => {
    setSizeFilter(size);
  };

  const loadMore = () => {
    if(hasMore){
      fetch(next)
      .then(res => res.json())
      .then(
        (result) => {
          result.data.sort((a,b) => 0.5 - Math.random());
          setImages(images.concat(result.data));
          if (result.meta.next) {
            setHasMore(true);
            setNext(result.meta.next);
          } else {
            setHasMore(false);
            setNext('');
          }
        },
        (error) => {
  
        }
      )
    }
  }

  const filterAll = () => {
    fetch("https://api.graduatetogetheryearbook.com/yearbook?limit=60")
    .then(res => res.json())
    .then(
      (result) => {
        result.data.sort((a,b) => 0.5 - Math.random());
        setImages(result.data);
        if (result.meta.next) {
          setHasMore(true);
          setNext(result.meta.next);
        } else {
          setNext('');
          setHasMore(false);
        }
      },
      (error) => {

      }
    )
  }

  const fetchStates = () => {
      fetch("https://api.graduatetogetheryearbook.com/feed/states")
      .then(res => res.json())
      .then(
        (result) => {
          setStates(result);
        },
        (error) => {
          
        }
      )
  }

  useEffect(() => {
    fetchStates();
    if (params.student) {
      fetch("https://api.graduatetogetheryearbook.com/student?id=" + params.student)
      .then(res => res.json())
      .then(
        (result) => {
          if(result) {
            setNext('');
            setHasMore(false);
            setImages([result]);
            setSelectedValue(result);
            setSelectedIndex(0);
            setOpen(true);
          } else {
            setNext('');
            setHasMore(false);
            setImages([]);
          }
        },
        (error) => {
  
        }
      );
    } else if (params.school) {
      const values = {
        state: params.state,
        city: params.city,
        name: params.school
      }
      filterSchool(null, values);
    } else if (params.city) {
      fetch("https://api.graduatetogetheryearbook.com/yearbook?limit=60&state=" + params.state + "&city=" + params.city)
      .then(res => res.json())
      .then(
        (result) => {
          if(result.data && result.data.length > 0) {
            if (result.meta.next) {
              setHasMore(true);
              setNext(result.meta.next);
            } else {
              setNext('');
              setHasMore(false);
            }
            setImages(result.data);
          } else {
            setNext('');
            setHasMore(false);
            setImages([]);
          }
        },
        (error) => {
  
        }
      );
    } else if (params.state) {
      fetch("https://api.graduatetogetheryearbook.com/yearbook?limit=60&state=" + params.state)
      .then(res => res.json())
      .then(
        (result) => {
          if(result.data && result.data.length > 0) {
            if (result.meta.next) {
              setHasMore(true);
              setNext(result.meta.next);
            } else {
              setNext('');
              setHasMore(false);
            }
            setImages(result.data);
          } else {
            setNext('');
            setHasMore(false);
            setImages([]);
          }
        },
        (error) => {
  
        }
      );
    } else {
      filterAll();
    }
  }, []);

  const [open, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(images[1]);
  const [selectedIndex, setSelectedIndex] = useState(1);

  const handleClickOpen = (image, index) => {
    setSelectedValue(image);
    setSelectedIndex(index);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const nextItem = () => {
    if (selectedIndex + 1 < images.length){
      const index = selectedIndex + 1;
      setSelectedIndex(index);
      setSelectedValue(images[index]);
      setOpen(true);
    }
  }

  const previousItem = () => {
    if (selectedIndex !== 0){
      const index = selectedIndex - 1;
      setSelectedIndex(index);
      setSelectedValue(images[index]);
      setOpen(true);
    }
  }

  const filterSchool = (event, values) => {
    if (values) {
      setSelectedSchool(values);
      let school = values.name;
      let state = values.state;
      let city = values.city;

      setShareUrl(encodeURIComponent([state,city,school].join('/')));

      fetch("https://api.graduatetogetheryearbook.com/yearbook?limit=60&school=" + encodeURIComponent(school) + "&state=" + state + "&city=" + city)
      .then(res => res.json())
      .then(
        (result) => {
          if(result.data && result.data.length > 0) {
            if (result.meta.next) {
              setHasMore(true);
              setNext(result.meta.next);
            } else {
              setNext('');
              setHasMore(false);
            }
            setImages(result.data);
          } else {
            setNext('');
            setHasMore(false);
            setImages([]);
          }
        },
        (error) => {
  
        }
      )
    } else {
      setSelectedSchool(null);
      setShareUrl('');
      filterAll();
    }
  }

  const filterState = (event, values) => {
    if (values) {
      setShareUrl(encodeURIComponent(values.name));
      setSelectedState(values);
      setCities(values.cities);
      setResetSchool(resetSchool ? false : true);

      fetch("https://api.graduatetogetheryearbook.com/yearbook?limit=60&state=" + values.name)
      .then(res => res.json())
      .then(
        (result) => {
          if(result.data && result.data.length > 0) {
            setImages([]);
            if (result.meta.next) {
              setHasMore(true);
              setNext(result.meta.next);
            } else {
              setNext('');
              setHasMore(false);
            }
            setImages(result.data);
          } else {
            setNext('');
            setHasMore(false);
            setImages([]);
          }
        },
        (error) => {
  
        }
      );
    } else {
      setNext('');
      setHasMore(false);
      setSelectedState(null);
      setSelectedCity(null);
      setSelectedSchool(null);
      setShareUrl('');
      filterAll();
    }
  }

  const filterCity = (event, values) => {
    if (values) {
      let state = selectedState !== null ? selectedState.name : "";
      setShareUrl(encodeURIComponent([state,values].join('/')));
      setSelectedCity(values);
      fetch("https://api.graduatetogetheryearbook.com/yearbook?limit=60&state=" + state + "&city=" + values)
      .then(res => res.json())
      .then(
        (result) => {
          if(result.data && result.data.length > 0) {
            if (result.meta.next) {
              setHasMore(true);
              setNext(result.meta.next);
            } else {
              setNext('');
              setHasMore(false);
            }
            setImages(result.data);
          } else {
            setNext('');
            setHasMore(false);
            setImages([]);
          }
        },
        (error) => {
  
        }
      );
    } else {
      setSelectedCity(null);
      setShareUrl(encodeURIComponent(selectedState.name));
      filterState(null, selectedState);
    }
  }

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClickPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = (e) => {
    e.preventDefault();
    setAnchorEl(null);
  };

  const openFacebookShare = () => {
    window.open(
      'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent("https://www.graduatetogetheryearbook.com/yearbook/" + shareUrl),
      'facebook-share-dialog',
      'width=626,height=436'
    );
  }

  const openPopover = Boolean(anchorEl);

  const setCount = () => {
    fetch('https://api.graduatetogetheryearbook.com/feed/submissions-count')
      .then(res => res.json())
      .then(
        (result) => {
          setSubmissionCount(result.count)
        },
        (error) => {
          console.error(error)
        }
      )
  };

  const getCounter = () => {
    setCount();
    const numberArr = submissionCount.toString().split('');
    const structure = numberArr.map((number, count, arr) => {
      return (
        <React.Fragment key={count}>
          <li>
            {number}
          </li>
          {
            (count === arr.length - 4 || count === arr.length - 7)
              &&
            <span>,</span>
          }
        </React.Fragment>
      )
    });

    return(
      <ul>
        {structure}
      </ul>
    );
  };

  return (
    <div className="feed-container">
      <div className="feed-content">
        <h1 className="yearbook-header">
          GRADUATE TOGETHER YEARBOOK
          CLASS OF 2020
        </h1>
        <p className="yearbook-description">
          In honor of the High School Class of 2020, the Inside Out Project invites all graduating high school seniors across the 
          country to be featured in the Graduate Together Yearbook, celebrating the class of 2020!
        </p>
        <div className='counter'>
          {getCounter()}
          <b> YEARBOOK PHOTOS SUBMITTED </b>
        </div>
        <div className="control-bar">
          <div className="feed-search">
            <HighSchoolSelect 
                onChange={filterSchool} 
                formStyle="outlined"
                className="high-school-filter"
                control={control}
                reset={resetSchool}
              />
          </div>
          <div className="feed-more-search">
            <Button 
              aria-describedby="filter-popover"
              variant="contained"
              color="primary"
              onClick={handleClickPopover}
              className={classNames('filter-button', {
                'filter-button-open': openPopover,
              })}
            >
              More Filters 
              {!openPopover && <img className="arrow-down" alt="down arrow" src={arrowDown} />}
              {openPopover && <img className="arrow-up" alt="up arrow" src={arrowUp} />}
            </Button>
            <Popover
              id="filter-popover"
              open={openPopover}
              anchorEl={anchorEl}
              onClose={handleClosePopover}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <div className="more-filters">
                <Autocomplete
                  options={states}
                  getOptionLabel={(option: any) => {
                    return option.name ? option.name : ""}}
                  onChange={filterState}
                  className="state-filter"
                  defaultValue={selectedState}
                  renderOption={(option: any) => (
                    <span>
                      {option.name}
                    </span>
                  )}
                  renderInput={params => (
                    <TextField
                      {...params}
                      placeholder="State"
                      variant="outlined"
                      value={selectedState}
                      defaultValue={selectedState}
                    />
                  )}
                />

                <Autocomplete
                  options={cities}
                  getOptionLabel={(option: any) => option}
                  onChange={filterCity}
                  disabled={!selectedState}
                  defaultValue={selectedCity}
                  value={selectedCity}
                  className="city-filter"
                  renderOption={option => (
                    <span>
                      {option}
                    </span>
                  )}
                  renderInput={params => (
                    <TextField
                      {...params}
                      placeholder="City"
                      variant="outlined"
                    />
                  )}
                />
              </div>
            </Popover>
            <div className="feed-filter">
              <input onClick={() => changeSizeFilter("max")} id='max-mob' type='radio' name='size-mob' defaultChecked={true}/>
              <label className='max' htmlFor='max-mob'/>
              <input onClick={() => changeSizeFilter("medium")} id='medium-mob' type='radio' name='size-mob'/>
              <label className='medium'  htmlFor='medium-mob'/>
              <input onClick={() => changeSizeFilter("min")} id='min-mob' type='radio' name='size-mob'/>
              <label className='min'  htmlFor='min-mob'/>
            </div>
          </div>
          <div className="feed-filter">
            <input onClick={() => changeSizeFilter("max")} id='max' type='radio' name='size' defaultChecked={true}/>
            <label className='max' htmlFor='max'/>
            <input onClick={() => changeSizeFilter("medium")} id='medium' type='radio' name='size'/>
            <label className='medium'  htmlFor='medium'/>
            <input onClick={() => changeSizeFilter("min")} id='min' type='radio' name='size'/>
            <label className='min'  htmlFor='min'/>
            <input onClick={() => changeSizeFilter("desktop-min")} id='desktop-min' type='radio' name='size'/>
            <label className='desktop-min'  htmlFor='desktop-min'/>
          </div>
        </div>
        <div className="feed-div">
          <div className="feed">
            {images && images.length < 1 && <h2>No yearbook images yet</h2>}
            {images && images.length > 0 && (
            <InfiniteScroll
                pageStart={0}
                loadMore={loadMore}
                hasMore={hasMore}
                loader={<div className="loader" key={0}>Loading ...</div>}
                useWindow={true}
            >
              {images.map((image, i) => {     
                return (
                  <div 
                    className={classNames('feed-item', {
                      'feed-item-max': sizeFilter === 'max',
                      'feed-item-medium': sizeFilter === 'medium',
                      'feed-item-min': sizeFilter === 'min',
                      'feed-item-desktop-min': sizeFilter === 'desktop-min',
                    })}
                    onClick={() => handleClickOpen(image, i)}
                    key={i}
                  >
                    <YearbookItem size={sizeFilter} image={image} />
                  </div>
                ) 
              })}
            </InfiniteScroll>
            )}
          </div>
          <div 
            className={classNames('share-overlay', {
              'share-overlay-search': selectedCity || selectedState || selectedSchool,
            })}
          >
            {(!selectedCity && !selectedState && !selectedSchool) && <p>SHARE</p>}
            {(selectedCity || selectedState || selectedSchool) && <p>SHARE SEARCH RESULTS</p>}
            <a 
              rel="noopener noreferrer" 
              target="_blank" 
              href={"https://twitter.com/intent/tweet?text=https://graduatetogetheryearbook.complex.com/yearbook/" + shareUrl}
            >
                <img 
                  className="twitter"
                  alt="twitter"
                  src={selectedCity || selectedState || selectedSchool ? twitterWhite : twitter}
                />
            </a>
            <img 
              onClick={openFacebookShare}
              className="facebook"
              alt="facebook"
              src={selectedCity || selectedState || selectedSchool ? facebookWhite : facebook}
            />
          </div>
        </div>
      </div>
      <StudentDialog 
        selectedValue={selectedValue}
        selectedIndex={selectedIndex}
        open={open}
        onNext={nextItem}
        onPrevious={previousItem}
        onClose={handleClose}
      />
    </div>
  );
}

export default Yearbook;