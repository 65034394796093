import React from 'react';
import './App.scss';
import Header from './components/Header/Header';
import Home from './components/Home/Home';
import Footer from './components/Footer/Footer';
import About from './components/About/About';
import Terms from './components/Terms/Terms';
import Privacy from './components/Privacy/Privacy';
import Form from './components/Form/Form';
import Yearbook from './components/Yearbook/Yearbook';
import FormSuccess from './components/Form/FormSuccess';
import NotFound from './components/NotFound/NotFound';
import CookieNotice from './components/CookieNotice/CookieNotice';
import Gallery from "./components/Gallery/Gallery";
import MapPage from "./components/Map/Map";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import ScrollToTop from './components/ScrollToTop/ScrollToTop'

function App() {
  return (
    <>
    <Router>
      <ScrollToTop />
      <div className="app-container" id="app-container">
        <Header />
        <Switch>
          <Redirect exact from="/" to="/yearbook" />
          <Redirect exact from="/form" to="/yearbook" />
          <Route path="/about">
            <About />
          </Route>
          <Route path="/terms">
            <Terms />
          </Route>
          <Route path="/privacy">
            <Privacy />
          </Route>
          <Route path="/form/success">
            <FormSuccess />
          </Route>
          <Route path="/form">
            <Form />
          </Route>
          <Route path="/gallery">
            <Gallery />
          </Route>
          <Route path="/map">
            <MapPage />
          </Route>
          <Route exact path="/yearbook/:state?/:city?/:school?/:student?" component={Yearbook}>
          </Route>
          <Route exact path="">
            <Home />
          </Route>
          <Route component={NotFound}/>
        </Switch>
        <Footer />
      </div>
    </Router>
    <CookieNotice />
    </>
  );
}

export default App;
