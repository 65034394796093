import React from 'react';
import './Gallery.scss';

import img from '../../assets/gallery/photo.png';

const Gallery = () => {
  return(
    <div className='gallery-container'>
      <h1> DISPLAY YOUR GRADUATE TOGETHER PHOTO OUTSIDE </h1>
      <p>
        Portraits submitted to the Inside Out Graduate Together Yearbook are being displayed all around the country to 
        celebrate the Class of 2020!
      </p>
      <div className='video-container'>
        <iframe
          className='video'
          width="560"
          height="315"
          src="https://www.youtube.com/embed/onFyPBGkQQo"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>
      <p className="video-text"><i>The faces of US High School graduates live on a billboard in Times Square, in New York City.</i></p>

      <div className='info'>
        <img className='photo' src={img} alt="brick wall with posters" />
        <div>
          <p>
            <b>The best submissions will receive poster-sized prints of their Graduate Together Yearbook Photos, to be displayed at 
            their schools or a public location.</b>
          </p>
        </div>
        <img className='photo-mob' src={img} alt="brick wall with posters" />
      </div>
      <a rel="noopener noreferrer" target="_blank" href="https://www.facebook.com/hashtag/graduatetogetheryearbook?__eep__=6&epa=HASHTAG">
        <p className="print-hashtag">#GraduateTogetherYearbook</p>
      </a>
    </div>
  )
};

export default Gallery;
