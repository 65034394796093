import React, {useEffect, useState} from 'react';
import { compose, withProps } from 'recompose'
import {withScriptjs, withGoogleMap, GoogleMap, Marker, InfoWindow } from 'react-google-maps'
import MarkerClusterer from "react-google-maps/lib/components/addons/MarkerClusterer";
import ClusterIcon from '../../assets/cluster_icon.svg'
import MarkerIcon from '../../assets/marker.svg'

import MapStyle from './mapStyles.json'

const GoogleMapStructure = compose(
  withProps({
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyA00UXbZch1XIHNxx--gCw8MdQySle1a-g&v=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: '100%' }} />,
    containerElement: <div style={{ height: '75vh' }} />,
    mapElement: <div style={{ height: '100%' }} />,
  }),
  withScriptjs,
  withGoogleMap
)(() => {
  const [markersInfo, setMarkersInfo] = useState([]);
  const [showInfoName, setShowInfoName] = useState('');

  useEffect(() => {
    fetch("https://api.graduatetogetheryearbook.com/feed/schools-map")
      .then(res => res.json())
      .then(
        (result) => {
          return setMarkersInfo(result.data)
        },
        (err) => console.error(err)
      )
  }, []);

  function getClusters () {
    return (
      <MarkerClusterer
        enableRetinaIcons
        styles={[
          {
            textColor: 'white',
            url: ClusterIcon,
            height: 60,
            width: 60
          },
        ]}
        gridSize={60}
        minimumClusterSize={6}
      >
        {markersInfo.map((item, counter) => (
          <Marker
            position={{
              lat: +item.latitude,
              lng: +item.longitude
            }}
            icon={MarkerIcon}
            onClick={() => setShowInfoName(item.name)}
            key={counter}
          >
            {
              showInfoName === item.name
              &&
              <InfoWindow
                onCloseClick={() => setShowInfoName('')}
              >
                <React.Fragment>
                  <p
                    style={{
                      fontSize: "15px",
                      margin: "5px 0px"
                    }}
                  >{item.name}</p>
                  <p
                    style={{
                      fontSize: "10px",
                      margin: "5px 0px"
                    }}
                  >Number of submissions: {item.scount}</p>
                </React.Fragment>
              </InfoWindow>
            }
          </Marker>
        ))}
      </MarkerClusterer>
    )
  }

  return (
    <GoogleMap
      options={{
        styles: MapStyle,
        panControl: false,
      }}
      defaultZoom={5}
      defaultCenter	={{
        lat: 37.444,
        lng: -100.576
      }}
    >
      {getClusters()}
    </GoogleMap>
  )
});

export default GoogleMapStructure;
