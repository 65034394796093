import React from 'react';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import './ImageCarousel.scss';

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 8
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 7
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 4
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 3
  }
};

const textResponsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 6
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 6
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 6
  }
};

const images = [
  "https://images.graduatetogetheryearbook.com/graduatetogether/image/upload/c_fill,g_face:center,w_680,h_1000,q_auto/e_unsharp_mask:250/e_auto_contrast/e_auto_brightness/v1588617947/tests/batch1-6-relens/uhznk0s4g9pcv36t30x7uhzn8pwefuh0-_-NewYork-ArchbishopMolloyHighSchool-ArchbishopMolloyHighSchool-Ani-Milla",
  "https://images.graduatetogetheryearbook.com/graduatetogether/image/upload/c_fill,g_face:center,w_680,h_1000,q_auto/e_unsharp_mask:250/e_auto_contrast/e_auto_brightness/v1588617947/tests/batch1-6-relens/ttan0wxexqpmjs6qttanjhnvqu59gp85-_-Florida-DelrayBeach-Atlantichighschool-Jason-Mesidor",
  "https://images.graduatetogetheryearbook.com/graduatetogether/image/upload/c_fill,g_face:center,w_680,h_1000,q_auto/e_unsharp_mask:250/e_auto_contrast/e_auto_brightness/v1588617932/tests/batch1-6-relens/bdpoiyq77ghnfm7uhcdjtxbdpoiyqszi-_-Michigan-Detroit-CassTechnicalHighSchool-Walter-Kearney",
  "https://images.graduatetogetheryearbook.com/graduatetogether/image/upload/c_fill,g_face:center,w_680,h_1000,q_auto/e_unsharp_mask:250/e_auto_contrast/e_auto_brightness/v1588615736/tests/batch6/batch6_o1ny1psnh4szu9jhhbi65t1o1ny1a62j-_-California-Temple_City-Temple_City_High_School-Jaemen-Topalian",
  "https://images.graduatetogetheryearbook.com/graduatetogether/image/upload/c_fill,g_face:center,w_680,h_1000,q_auto/e_unsharp_mask:250/e_auto_contrast/e_auto_brightness/v1588615735/tests/batch6/batch6_k9adxevuycg89k43fk9adx46yfq87ok7-_-Ohio-Maple_Heights_High_School-Maple_Heights_High_School-Gabrielle-McCorvey",
  "https://images.graduatetogetheryearbook.com/graduatetogether/image/upload/c_fill,g_face:center,w_680,h_1000,q_auto/e_unsharp_mask:250/e_auto_contrast/e_auto_brightness/v1588615731/tests/batch6/batch6_pkisgybtv8gl65y1o9ixipkisg0a3epb-_-Arizona-Sahuarita-Sahuarita_High_School-Barbara-Ochoa",
  "https://images.graduatetogetheryearbook.com/graduatetogether/image/upload/c_fill,g_face:center,w_680,h_1000,q_auto/e_unsharp_mask:250/e_auto_contrast/e_auto_brightness/e_grayscale/v1588615730/tests/batch6/batch6_u6lagpa6bq0idpon4h7ffu6lagpa1mqy-_-Virginia-Virginia_Beach-Ocean_Lakes_High_School_and_the_Governor_s_School_for_the_Arts-Gabrielle-Pinkney.jpg",
  "https://images.graduatetogetheryearbook.com/graduatetogether/image/upload/c_fill,g_face:center,w_680,h_1000,q_auto/e_unsharp_mask:250/e_auto_contrast/e_auto_brightness/v1588615729/tests/batch6/batch6_f5gaogub0c417m3rf5gao8cpvpai95g6-_-Florida-Miami-Barbara_Goleman_Senior_High_School-Erich-Valerdi.jpg",
  "https://images.graduatetogetheryearbook.com/graduatetogether/image/upload/c_fill,g_face:center,w_680,h_1000,q_auto/e_unsharp_mask:250/e_auto_contrast/e_auto_brightness/v1588615727/tests/batch6/batch6_kf0ceuc1ucz6ebs64xkf0ceuc7q2ysvc-_-Texas-Houston-Benjamin_O_Davis_High_School-Dominick-Nelson.jpg",
  "https://images.graduatetogetheryearbook.com/graduatetogether/image/upload/c_fill,g_face:center,w_680,h_1000,q_auto/e_unsharp_mask:250/e_auto_contrast/e_auto_brightness/v1588607131/tests/batch5/batch5_x3kmabrpxju01l0lgcl04ux3kmabzim3-_-Oklahoma-Bartlesville-Bartlesville_High_School-Haley-Stevens.jpg",
  "https://images.graduatetogetheryearbook.com/graduatetogether/image/upload/c_fill,g_face:center,w_680,h_1000,q_auto/e_unsharp_mask:250/e_auto_contrast/e_auto_brightness/v1588607121/tests/batch5/batch5_yn44q7myiwei57fyn4kyrbn81w4yd1ws-_-Nebraska-Norris_High_School-Norris_High_School-Grayson-Meyer.jpg",
  "https://images.graduatetogetheryearbook.com/graduatetogether/image/upload/c_fill,g_face:center,w_680,h_1000,q_auto/e_unsharp_mask:250/e_auto_contrast/e_auto_brightness/v1588607118/tests/batch5/batch5_ry3svc0i835d0hojlpry3sv6bzqu9z6f-_-California-Culver_City-Culver_City_High_School-Marcus-Block.jpg",
  "https://images.graduatetogetheryearbook.com/graduatetogether/image/upload/c_fill,g_face:center,w_680,h_1000,q_auto/e_unsharp_mask:250/e_auto_contrast/e_auto_brightness/v1588607110/tests/batch5/batch5_t4bd9u9bf4f5wh1t4bd39glh0p6h3ri6-_-Wisconsin-Monona-Monona_Grove_High_School-Annalisa-Brandmeier.jpg",
  "https://images.graduatetogetheryearbook.com/graduatetogether/image/upload/c_fill,g_face:center,w_680,h_1000,q_auto/e_unsharp_mask:250/e_auto_contrast/e_auto_brightness/v1588607100/tests/batch5/batch5_zme649xggntog18hozme68gw07eg221e-_-California-Riverside-Poly_High_School-Camryn-Atwood.jpg",
  "https://images.graduatetogetheryearbook.com/graduatetogether/image/upload/c_fill,g_face:center,w_680,h_1000,q_auto/e_unsharp_mask:250/e_auto_contrast/e_auto_brightness/v1588607100/tests/batch5/batch5_tlrjasgh598mlpuq0tftlrjxot46bviz-_-Rhode_Island-North_Scituate-Ponaganset_High_School-Maggie-Nardelli.jpg",
  "https://images.graduatetogetheryearbook.com/graduatetogether/image/upload/c_fill,g_face:center,w_680,h_1000,q_auto/e_unsharp_mask:250/e_auto_contrast/e_auto_brightness/v1588607095/tests/batch5/batch5_bbipvyar17rztxugf1ldbbin86sr1ax8-_-Virginia-Ashburn_VA-Broad_Run_High_School-Nawal-Siddiqui.jpg",
  "https://images.graduatetogetheryearbook.com/graduatetogether/image/upload/c_fill,g_face:center,w_680,h_1000,q_auto/e_unsharp_mask:250/e_auto_contrast/e_auto_brightness/v1588607092/tests/batch5/batch5_s8oxcja20fdp78px46t0cmrws8oxcja2-_-Texas-Killeen-C.E._Ellison_High_School-Alexus-Bowers.jpg",
  "https://images.graduatetogetheryearbook.com/graduatetogether/image/upload/c_fill,g_face:center,w_680,h_1000,q_auto/e_unsharp_mask:250/e_auto_contrast/e_auto_brightness/v1588607090/tests/batch5/batch5_tz4gpuza2kzn8qw1tz4gpubp3y9u1ob4-_-Georgia-Atlanta-Drew_Charter_School-Omar-Murphy.jpg",
  "https://images.graduatetogetheryearbook.com/graduatetogether/image/upload/c_fill,g_face:center,w_680,h_1000,q_auto/e_unsharp_mask:250/e_auto_contrast/e_auto_brightness/v1588362269/tests/batch4/p1jw38hqykalvywa0se1902p1jw38ho6-_-Michigan-Dexter_High_School-Dexter_High_School-Zoe%CC%88-Murphy.jpg",
  "https://images.graduatetogetheryearbook.com/graduatetogether/image/upload/c_fill,g_face:center,w_680,h_1000,q_auto/e_unsharp_mask:250/e_auto_contrast/e_auto_brightness/v1588362267/tests/batch4/an904b61fg75byrgan90oogcyy6akrfj-_-Texas-McKinney-Point_of_Hope_Christian_Academy-Blake-Castaneda.jpg",
  "https://images.graduatetogetheryearbook.com/graduatetogether/image/upload/c_fill,g_face:center,w_680,h_1000,q_auto/e_unsharp_mask:250/e_auto_contrast/e_auto_brightness/v1588362266/tests/batch4/af4kyehvbowmx082af4k9ccobtxrufnu-_-Washington_DC-Washington_DC-Maret_School-Beya-Hull.jpg",
  "https://images.graduatetogetheryearbook.com/graduatetogether/image/upload/c_fill,g_face:center,w_680,h_1000,q_auto/e_unsharp_mask:250/e_auto_contrast/e_auto_brightness/v1588362264/tests/batch4/3u9q1dwaqhsd2kr7r13u9q1w2fzsuay5-_-Texas-Fort_Worth-Chisholm_Trail_High_School-Ashley-Read.jpg",
  "https://images.graduatetogetheryearbook.com/graduatetogether/image/upload/c_fill,g_face:center,w_680,h_1000,q_auto/e_unsharp_mask:250/e_auto_contrast/e_auto_brightness/v1588879391/carousel/dcaba771dc69-F832B2E7_65F5_4B26_B455_81E51AB8F6CF.jpg",
  "https://images.graduatetogetheryearbook.com/graduatetogether/image/upload/c_fill,g_face:center,w_680,h_1000,q_auto/e_unsharp_mask:250/e_auto_contrast/e_auto_brightness/v1588879391/carousel/8b94c10405a0-2E4D9D28_13DC_496D_BA95_50DDBDEBFC89.jpg",
  "https://images.graduatetogetheryearbook.com/graduatetogether/image/upload/c_fill,g_face:center,w_680,h_1000,q_auto/e_unsharp_mask:250/e_auto_contrast/e_auto_brightness/v1588879389/carousel/3fd330627b63-3640B68E_6F41_4347_899F_B1958E9CBA57.jpg",
  "https://images.graduatetogetheryearbook.com/graduatetogether/image/upload/c_fill,g_face:center,w_680,h_1000,q_auto/e_unsharp_mask:250/e_auto_contrast/e_auto_brightness/v1588879388/carousel/c0c5b698891d-9C92945C_381D_4873_92DA_FB840D7A2054.jpg",
  "https://images.graduatetogetheryearbook.com/graduatetogether/image/upload/c_fill,g_face:center,w_680,h_1000,q_auto/e_unsharp_mask:250/e_auto_contrast/e_auto_brightness/v1588879387/carousel/7f3b7d86589e-9C24CF96_AC18_4792_B3C7_DCFFD4841773.jpg",
  "https://images.graduatetogetheryearbook.com/graduatetogether/image/upload/c_fill,g_face:center,w_680,h_1000,q_auto/e_unsharp_mask:250/e_auto_contrast/e_auto_brightness/v1588879386/carousel/e0ef97d42973-Snapchat_1607524647.jpg",
  "https://images.graduatetogetheryearbook.com/graduatetogether/image/upload/c_fill,g_face:center,w_680,h_1000,q_auto/e_unsharp_mask:250/e_auto_contrast/e_auto_brightness/v1588879385/carousel/afa671a4f911-0B0AA048_6EA5_4EA7_A6D8_77A8AA2B32E8.jpg",
  "https://images.graduatetogetheryearbook.com/graduatetogether/image/upload/c_fill,g_face:center,w_680,h_1000,q_auto/e_unsharp_mask:250/e_auto_contrast/e_auto_brightness/v1588879385/carousel/cf8f341565ca-6E89807E_2057_493E_860E_4025EF413BD4.jpg",
  "https://images.graduatetogetheryearbook.com/graduatetogether/image/upload/c_fill,g_face:center,w_680,h_1000,q_auto/e_unsharp_mask:250/e_auto_contrast/e_auto_brightness/v1588879383/carousel/5f44355bb0dc-6663AC1A_313F_46FE_B81B_87F7174047C0.jpg",
  "https://images.graduatetogetheryearbook.com/graduatetogether/image/upload/c_fill,g_face:center,w_680,h_1000,q_auto/e_unsharp_mask:250/e_auto_contrast/e_auto_brightness/v1588879382/carousel/c8b5412eab54-0CABFBE1_02C5_49C1_83CF_5A9038662880.jpg",
  "https://images.graduatetogetheryearbook.com/graduatetogether/image/upload/c_fill,g_face:center,w_680,h_1000,q_auto/e_unsharp_mask:250/e_auto_contrast/e_auto_brightness/v1588879381/carousel/8f2415fd8969-C4A858B6_1CE3_4481_A8B4_A2077D9FB4D3.jpg",
  "https://images.graduatetogetheryearbook.com/graduatetogether/image/upload/c_fill,g_face:center,w_680,h_1000,q_auto/e_unsharp_mask:250/e_auto_contrast/e_auto_brightness/v1588879380/carousel/c5b6dc076a53-85962E30_AC69_4BBA_B259_310FDFD544A2.jpg",
  "https://images.graduatetogetheryearbook.com/graduatetogether/image/upload/c_fill,g_face:center,w_680,h_1000,q_auto/e_unsharp_mask:250/e_auto_contrast/e_auto_brightness/v1588879379/carousel/e3e6928d31ca-C91E8670_BE3F_420A_A1C9_426009210F15.jpg",
  "https://images.graduatetogetheryearbook.com/graduatetogether/image/upload/c_fill,g_face:center,w_680,h_1000,q_auto/e_unsharp_mask:250/e_auto_contrast/e_auto_brightness/v1588879378/carousel/6106da255825-6AA1B7A2_D238_4C74_B75D_2062A09529B2.jpg",
  "https://images.graduatetogetheryearbook.com/graduatetogether/image/upload/c_fill,g_face:center,w_680,h_1000,q_auto/e_unsharp_mask:250/e_auto_contrast/e_auto_brightness/v1588879377/carousel/eaec1a2adfdb-1EBDBBF3_3A4A_468C_84E4_28729C3C6870.jpg",
  "https://images.graduatetogetheryearbook.com/graduatetogether/image/upload/c_fill,g_face:center,w_680,h_1000,q_auto/e_unsharp_mask:250/e_auto_contrast/e_auto_brightness/v1588879376/carousel/444332897ceb-E147185D_AA69_4587_A5C0_98982AAFE295.jpg",
  "https://images.graduatetogetheryearbook.com/graduatetogether/image/upload/c_fill,g_face:center,w_680,h_1000,q_auto/e_unsharp_mask:250/e_auto_contrast/e_auto_brightness/v1588879375/carousel/418786714ec3-F06BB11D_192A_4D49_9724_7024814B4EBD.jpg",
  "https://images.graduatetogetheryearbook.com/graduatetogether/image/upload/c_fill,g_face:center,w_680,h_1000,q_auto/e_unsharp_mask:250/e_auto_contrast/e_auto_brightness/v1588879374/carousel/8bf31aa17d5f-0FDBF84D_F648_4826_A646_8C3218390D51.jpg",
  "https://images.graduatetogetheryearbook.com/graduatetogether/image/upload/c_fill,g_face:center,w_680,h_1000,q_auto/e_unsharp_mask:250/e_auto_contrast/e_auto_brightness/v1588879373/carousel/3dc267caaf3f-16A16585_EEC3_4ECA_97D0_CD5E6F42281F.jpg",
  "https://images.graduatetogetheryearbook.com/graduatetogether/image/upload/c_fill,g_face:center,w_680,h_1000,q_auto/e_unsharp_mask:250/e_auto_contrast/e_auto_brightness/v1588879372/carousel/2eb472a93a1a-95CA3960_E6F8_4BAE_B34E_495B6E3CD3DF.jpg",
  "https://images.graduatetogetheryearbook.com/graduatetogether/image/upload/c_fill,g_face:center,w_680,h_1000,q_auto/e_unsharp_mask:250/e_auto_contrast/e_auto_brightness/v1588879371/carousel/c1101b73f0a2-77A3DB07_20D2_4D99_AC15_A281BE6530E9.jpg",
  "https://images.graduatetogetheryearbook.com/graduatetogether/image/upload/c_fill,g_face:center,w_680,h_1000,q_auto/e_unsharp_mask:250/e_auto_contrast/e_auto_brightness/v1588879370/carousel/8498d4c158f4-73FB2328_8DFE_469B_875E_4FE23AA51A2E.jpg"
];

images.sort((a,b) => 0.5 - Math.random());

const ImageCarousel = () => {
  return (
    <>
      <Carousel
        responsive={responsive}
        swipeable={true}
        autoPlay={true}
        infinite={true}
        autoPlaySpeed={2000}
        removeArrowOnDeviceType={["desktop", "tablet", "mobile"]}
        className="image-carousel"
      >
        {images.map((image, i) => {     
            return (<div className="student-item" key={i}><img src={image} alt="student" /></div>) 
        })}
      </Carousel>
      <Carousel
        responsive={textResponsive}
        swipeable={true}
        autoPlay={true}
        infinite={true}
        autoPlaySpeed={2000}
        removeArrowOnDeviceType={["desktop", "tablet", "mobile"]}
        className="text-carousel"
        customTransition= "all 2s linear"
      >
        <p>#GraduateTogetherYearbook</p>
        <p>#GraduateTogetherYearbook</p>
        <p>#GraduateTogetherYearbook</p>
        <p>#GraduateTogetherYearbook</p>
        <p>#GraduateTogetherYearbook</p>
        <p>#GraduateTogetherYearbook</p>
        <p>#GraduateTogetherYearbook</p>
        <p>#GraduateTogetherYearbook</p>
        <p>#GraduateTogetherYearbook</p>
        <p>#GraduateTogetherYearbook</p>
        <p>#GraduateTogetherYearbook</p>
        <p>#GraduateTogetherYearbook</p>
        <p>#GraduateTogetherYearbook</p>
        <p>#GraduateTogetherYearbook</p>
        <p>#GraduateTogetherYearbook</p>
      </Carousel>
    </>
  );
}

export default ImageCarousel;