import React from 'react';
import './About.scss';
import ImageCarousel from '../ImageCarousel/ImageCarousel';

const About = () => {
  return(
    <>
      <ImageCarousel />
      <div className="about-container">
        <div className='about-title'>
          <h1>ABOUT GRADUATE TOGETHER YEARBOOK</h1>
          <p>
            Graduate Together Yearbook honors the High School Class of 2020, inviting all high school seniors whose graduations are canceled due to the coronavirus to participate in the largest yearbook, ever.
          </p> 
          <p>
            This nationwide initiative was created by renowned artist JR, his global participatory art project, Inside Out, and XQ Institute.
          </p>
        </div>
        <div className="about-content">
          <div className='about-info-block'>
            <img
              alt='people walking on portraits'
              src='https://images.graduatetogetheryearbook.com/graduatetogether/image/upload/c_scale,w_620,q_80/v1588986362/webassets/about-iop_2x.5c4b645a.jpg'
            />
            <div className='about-text-container'>
              <div className='about-text'>
                <h2>
                  About the <br/>
                  Inside Out Project
                </h2>
                <img
                  alt='people walking on portraits'
                  src='https://images.graduatetogetheryearbook.com/graduatetogether/image/upload/c_scale,w_620,q_80/v1588986362/webassets/about-iop_2x.5c4b645a.jpg'
                />
                <p>
                  The Inside Out Project was launched in 2011 when JR won the TED Prize, enabling him to 
                  create a global art project that would have the potential to change the world.
                </p>
                <p>
                  Inspired by JR’s large-format street “pastings”, The Inside Out Project is a Global Participatory Art Project that gives everyone 
                  the opportunity to share a message by displaying large-scale black and white portraits of members from their community in public spaces. 
                  Anyone can share their untold stories and make a statement for what they stand for by creating works of public art.
                </p>
                <p>
                  Every group action around the world is documented and exhibited online. Since 2011 more than 362,700 people have participated in 142 countries.
                </p>
                <p>
                  Anyone can start a Group Action by becoming a group leader and committing to lead their own, independently organized project anywhere in the world.
                </p>
                <p>
                  Over 30% of all Inside Out Project Group Actions around the world are done at schools, with students from age 3 to 21. Our goal is 
                  to provide students and teachers with a tool to express themselves through their portraits. Now it’s your turn!
                </p>
                <p>
                  Learn more about the Inside Out Project on:<br/>
                  <a rel="noopener noreferrer" target="_blank" href="http://www.insideoutproject.net/">insideoutproject.net</a>
                </p>
                <p>
                  Learn more about school actions on:<br/>
                  <a rel="noopener noreferrer" target="_blank" href="http://insideoutprojecteducation.net/">insideoutprojecteducation.net</a>
                </p>
              </div>
            </div>
          </div>
          <div className='about-info-block'>
            <div className='about-text-container'>
              <div className='about-text'>
                <h2>
                  About XQ
                </h2>
                <img
                  alt="bus with drawings"
                  src="https://images.graduatetogetheryearbook.com/graduatetogether/image/upload/c_scale,w_620,q_80/v1588986363/webassets/about-qx_2x.da092ec7.jpg"
                />
                <p>
                  XQ Institute is the nation’s leading organization dedicated to rethinking the high school 
                  experience so that every student graduates ready to succeed in life.
                </p>
                <p>
                  We work in communities throughout the country, with individual schools and entire school systems, to help them dream big about what high school could be, turn their innovative ideas into action, 
                  and create more rigorous and equitable schools. We share what’s working with other schools and districts, so they can tailor new models to the needs of their own students and communities. 
                  We know that seeing is believing, so we meet people where they are to share the importance and possibility of change through listening, learning, and storytelling. And our open-source tools, 
                  based on research and design thinking, empower people to take up the challenge of transforming their high schools with a unifying goal: unlocking the American promise of a high-quality education for everyone.
                </p>
                <p>
                  XQ was co-founded by board chair Laurene Powell Jobs, founder and president of Emerson Collective, and Russlynn Ali, XQ’s CEO and former U.S. assistant secretary of 
                  education for civil rights. XQ’s board of directors also includes Geoffrey Canada, Marc Eckō, Jimmy Iovine, Michael Klein, and Yo-Yo Ma.
                </p>
                <p>
                  To learn more, visit <a rel="noopener noreferrer" target="_blank" href="https://xqsuperschool.org/">www.xqsuperschool.org</a> and follow <a rel="noopener noreferrer" target="_blank" href="https://twitter.com/xqamerica">@xqamerica</a>.
                </p>
              </div>
            </div>
            <img
              alt="bus with drawings"
              src="https://images.graduatetogetheryearbook.com/graduatetogether/image/upload/c_scale,w_620,q_80/v1588986363/webassets/about-qx_2x.da092ec7.jpg"
            />
          </div>
          <div className='about-info-block'>
            <img
              alt="time magazine cover"
              src="https://images.graduatetogetheryearbook.com/graduatetogether/image/upload/c_scale,w_620,q_80/v1588986365/webassets/about-jr_2x.4314009a.jpg"
            />
            <div className='about-text-container'>
              <div className='about-text'>
                <h2>
                  About JR
                </h2>
                <img
                  alt="time magazine cover"
                  src="https://images.graduatetogetheryearbook.com/graduatetogether/image/upload/c_scale,w_620,q_80/v1588986365/webassets/about-jr_2x.4314009a.jpg"
                />
                <p>
                  JR exhibits freely in the streets of the world, catching the attention of people who are not typical museum visitors, from the suburbs of Paris 
                  to the slums of Brazil to the streets of New York, pasting huge portraits of anonymous people, from Kibera to Istanbul, from Los Angeles to Shanghai. 
                  In 2011 he received the TED Prize, and launched the Inside Out Project. His recent projects include a large-scale pasting in a maximum security prison 
                  in California, a TIME Magazine cover about Guns in America, a video mural including 1,200 people presented at SFMOMA, a collaboration with New York City Ballet, 
                  an Academy Award Nominated feature documentary co-directed with Nouvelle Vague legend Agnès Varda, the pasting of a container ship, the pyramid of the Louvre, 
                  a monumental mural “à la Diego Rivera” in the suburbs of Paris, giant scaffolding installations at the 2016 Rio Olympics, an exhibition on the abandoned hospital 
                  of Ellis Island, a social restaurant for homeless and refugees in Paris or a gigantic installation at the US-Mexico border fence. As he remains anonymous, 
                  JR leaves the space empty for an encounter between the subject/protagonist and the passer-by/interpreter. That is what JR's work is about, raising questions.
                </p>

                <p>
                  <strong>
                    Can Art Change the World? 
                  </strong>
                </p>

                <a rel="noopener noreferrer" target="_blank" href="http://www.jr-art.net/">www.jr-art.net</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
};

export default About;
