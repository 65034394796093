import React from 'react';
import './CookieNotice.scss';

const CookieNotice = () => {
  return (
    <div id="eucn" className="eucn">
      <div className="main-container">
        <p className="eucn__notice">
          This site uses cookies. By using this site, you agree to
          our:&nbsp;<a href="/privacy">Privacy&nbsp;Policy</a> and <a href="/terms">Terms&nbsp;of&nbsp;Service</a>.
        </p>
        <button id="eucn_accept" className="eucn__accept">OK</button>
      </div>
    </div>
  );
}

export default CookieNotice;