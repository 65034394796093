import React from 'react';
import './Privacy.scss';


const Privacy = () => {
  return (
    <div className="privacy">
      <div className="title">
        <h1>PRIVACY POLICY</h1>
      </div>

      <div className="privacy-content">
        <p>
          Unframed respects and values the privacy of all visitors to the website for our Graduate Together Yearbook initiative (“Initiative”) and those engaging with us through social media and our other applications. This privacy policy is to help you understand what information Unframed collects, how Unframed uses and shares that information, and what choices you have with respect to the foregoing. This privacy policy governs your interaction with us, including the Initiative website, social media, and other applications (collectively, the “Applications”). This privacy policy does not apply to the data collection and use practices of third parties that Unframed does not employ, manage or control. When you use the Applications, you consent to our collection, use and disclosure of information about you as described in this privacy policy. Additionally, our Terms of Use for the Initiative (“Terms of Use”) are incorporated by reference into this privacy policy. The terms “Unframed,” “we,” “us,” or “our” refer, collectively, to Unframed USA Ltd., a New York corporation, headquartered at 11 Spring St., New York, New York 10012 and its project, the Inside Out Project.
        </p>

        <p className="privacy-header">COLLECTION AND USE OF INFORMATION</p>

        <p>Unframed collects information that you provide to us directly and information that is made available when you use our Applications. We may use such collected information to, among other things: operate, maintain, enhance and provide the Applications’ functionality; provide services and information to you; personalize your experience; monitor Applications usage; provide user support; generate and review reports and data about the Applications’ user base and service usage patterns; compile aggregate data; aggregate the information with data provided by third parties; resolve disputes and troubleshoot problems; message you (in ways described by this privacy policy); prevent, detect and investigate potentially prohibited or illegal activities or a breach of the Terms of Use; and to comply with legal obligations.</p>

        <p>Of further note about the information we collect: </p>

        <ol>
          <li>
            <strong>Content Sharing:</strong> The Content (as defined below) you submit on the Applications is intended for use in accordance with the release you agree to in association with the submission of such Content (your “Release”), which Release is located at https://www.graduatetogetheryearbook.com. By submitting Content on the Applications, you agree that such Content may be viewed by Unframed and XQ Institute (“XQ”) and may otherwise be used in accordance with your Release. “Content” means, collectively, the following: (i) any photo(s) you upload to the Application; (ii) your likeness, image, appearance, name (including nicknames), biographical information, and other indicia identifying you; (iii) any quotes, stories, answers, opinions, statements or other written material that you submit to the Application; and (iv) any other information or content you submit or provide during your use of the Applications or the Services.
          </li>
          <li>
            <strong>Communications:</strong> You are opting in to receive communications from us and/or XQ about the Initiative, including promotional, marketing, and advertising information and recommendations that Unframed and/or XQ believes may be of interest to you. Consistent with applicable laws, including United States CAN-SPAM laws, you can opt out of certain email communications by clicking “unsubscribe” on those communications, but note that you cannot opt out of receiving certain administrative or legal notices from us.
          </li>
          <li>
            <strong>Applications Activity/Automatically Collected Information:</strong> We collect and may store information about your use of the Applications, such as your search activity, the pages you view, and the date and time of your visit. We also collect and may store information that your computer or mobile device provides to us in connection with your use of the Applications, such as your browser type and version, type of computer or mobile device, computer connection, browser language, IP address, ad data, mobile carrier, unique device identifier, Wi-Fi connection, geo-location, and requested and referring URLs.
          </li>
        </ol>

        <p className="privacy-header">COOKIES AND OTHER TRACKING TECHNOLOGIES</p>
        <p>We, and third parties we interact with, including our third-party advertisers and service providers, may use cookies, web beacons, local shared objects (sometimes called “flash cookies”), and similar technology in connection with your use of the Applications and third-party websites (collectively referred to in this policy as “Cookies”). Cookies are small data files that may have unique identifiers, and reside, among other places, on your computer or mobile device, in emails we send to you, and on our Applications. </p>

        <p>We may use Cookies to collect information about you and your use of the Applications, such as your browser type, search preferences, data relating to content that has been displayed to you or that you have clicked on, and the date and time of your use. When we use Cookies, we do so in order to further Applications features and processes, provide authentication and security for your transactions on the Applications, store your preferences, facilitate relevant advertising, and help us learn more about how users engage with the Applications. Our Cookies, known as first-party cookies, may be persistent or targeted around a user’s session. </p>

        <p>Unframed also uses third-party service providers to help us provide certain features, information and services or to integrate other features. These third-party service providers may collect information when you view or use them, including information about you and your device or browser. These third-party service providers also may use Cookies, known as third-party cookies, or similar technologies to help share information with us, like how you use their website or application. To learn more about the information they collect or receive, review their privacy policies. </p>

        <p>It may be possible to disable Cookies through your device or browser settings. The method for disabling cookies may vary by device and browser, but can usually be found in preferences or security settings. Disabling cookies may, however, affect your ability to use the Applications. </p>

        <p className="privacy-header">CALIFORNIA DO NOT TRACK DISCLOSURE</p>
        <p>Please note we do not change system behavior within the Applications in response to browser requests not to be tracked. </p>


        <p className="privacy-header">SHARING YOUR INFORMATION</p>
        <p>We may share the information we collect about you with third parties, including (without limitation) XQ, in connection with your use of the Applications. Our information-sharing with third parties falls into these categories and purposes: </p>

        <ol>
          <li><strong>Released Purposes.</strong> The Release makes reference to certain usages of your Content, and we and/or XQ may use your Content in accordance with those usages. </li>
          <li><strong>Agents, Contractors, Business Partners and Service Providers:</strong> We may share your information with our agents, contractors, business partners or other service providers and third parties as needed to host, operate and carry out the performance and functionality of the Applications, improve the Applications, fulfill your requests, facilitate relevant marketing and sharing information about us and our activities to you, or provide customer support. We cannot control third parties’ use of your information. Generally, however, we limit the information provided to service providers to that which is reasonably necessary for them to perform their functions, and we require them to agree to maintain the confidentiality of such information. </li>
          <li><strong>Other Third Parties:</strong> We may share your information with certain third parties, including (without limitation) XQ, who may contact you regarding related education initiatives.</li>
          <li><strong>Affiliated Entities:</strong> We may share information from or about you with affiliates, joint ventures, or other companies under common control, in which case we will require them to honor this privacy policy.</li>
          <li><strong>Aggregate Information:</strong> Unframed may share information relating to visitors and users of the Application with affiliated or unaffiliated third parties on an aggregate basis. While this information will not identify you personally, in some instances these third parties may be able to combine this information with other data they have about you, or that they receive from third parties, in a manner that allows them to associate this aggregated data with your personal data. </li>
          <li><strong>Investigations:</strong> We may investigate and disclose information from or about you, as permitted by applicable law, if we have a good-faith belief that such investigation or disclosure is (a) reasonably necessary to comply with legal process and law enforcement instructions and orders, such as a search warrant, subpoena, statute, judicial proceeding or other legal process served on us; (b) helpful to prevent, investigate or identify possible wrongdoing in connection with the Applications; or (c) helpful to protect our rights, reputation, property or that of our users, subsidiaries or the public.</li>
        </ol>

        <p className="privacy-header">CHILDREN’S PRIVACY</p>
        <p>Protecting the privacy of young children is especially important. The Applications are intended for general audiences and are not directed to children under 13. We do not knowingly collect personal information from children under 13. If you are under 13 years of age, then you are not permitted to use the Applications in any manner that reveals personally identifiable information about yourself (such as your name, email, address or location). </p>

        <p className="privacy-header">DATA SECURITY</p>
        <p>We use certain physical, managerial, and technical safeguards that are designed to improve the integrity and security of your personally identifiable information. We cannot, however, ensure or warrant the security of any information you transmit to us or store on the Applications, and you do so at your own risk. We cannot guarantee that such information may not be accessed, disclosed, altered, or destroyed by breach of any of our physical, technical, or managerial safeguards. </p>

        <p>If we learn of a security systems breach, then we may attempt to notify you electronically so that you can take appropriate protective steps. We may post a notice through the Applications if a security breach occurs. Depending on where you live, you may have a legal right to receive notice of a security breach in writing. To receive a free written notice of a security breach you should notify us at yearbook@insideoutproject.net. </p>

        <p className="privacy-header">CALIFORNIA PRIVACY RIGHTS</p>
        <p>Under California Law Section 1798.83, California residents can request in writing from businesses with whom they have an established business relationship (a) a list of the categories of personal information and the type of services provided to the customer that a business has disclosed to third parties (including affiliates that are separate legal entities) during the immediately preceding calendar year for the third parties’ direct marketing purposes and (b) the names and addresses of all such third parties. To make such a request from us, if entitled, please use the contact information listed below. We will respond to such properly addressed written requests. Please note that we are only required to respond to each customer once per calendar year. </p>

        <p className="privacy-header">MODIFICATIONS AND UPDATES TO THIS PRIVACY POLICY</p>
        <p>We may revise this privacy policy from time to time. The most current version of the privacy policy will govern our use of information about you and will be located here: https://www.graduatetogetheryearbook.com/privacy. If we make material changes to this privacy policy, we may notify you by email or by posting a notice on the Applications. By continuing to access or use the Applications after those changes become effective, you agree to be bound by the revised privacy policy </p>


        <p className="privacy-header">CONTACT</p>
        <p>If you have questions or comments about this privacy policy, please feel free to contact us at <a href="mailto:">yearbook@insideoutproject.net.</a></p>
        <p>Updated as of May 16th, 2020</p>
      </div>
    </div>
  );
}

export default Privacy;
