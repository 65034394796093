import React, {useState, useEffect, useRef} from 'react';
import './Form.scss';
import { useForm, Controller } from 'react-hook-form';
import { useHistory } from "react-router-dom";
import ImageCarousel from '../ImageCarousel/ImageCarousel';

import check from '../../assets/check.svg';
import error from '../../assets/error.svg';
import upload from '../../assets/upload.svg';
import snapchat from '../../assets/snapchat1.png';
import goodExample from '../../assets/image_good.jpg';
import badExample from '../../assets/image_bad.jpg';
import goodExampleMob from '../../assets/image_good_mob.jpg';
import badExampleMob from '../../assets/image_bad_mob.jpg';

import classNames from 'classnames';
import Dropzone from 'react-dropzone';
import TextField from '@material-ui/core/TextField';
import HighSchoolSelect from './HighSchoolSelect/HighSchoolSelect';
import StateSelect from './StateSelect/StateSelect';
import Checkbox from '@material-ui/core/Checkbox';
import RadioGroup from '@material-ui/core/RadioGroup';
import Snackbar from '@material-ui/core/Snackbar';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MuiAlert from '@material-ui/lab/Alert';

import ReCAPTCHA from "react-google-recaptcha";

const Form = () => {
  const { register, handleSubmit, errors, formState, getValues, setValue, control, reset, triggerValidation } = useForm({ mode: `onBlur`}); // initialise the hook
  const { isSubmitting } = formState;
  const [captchaValidating, setCaptchaValidating] = useState(false);
  const [open, setOpen] = useState(false);
  const [charsLeft, setCharsLeft] = useState(500);
  const [uploadErrorOpen, setUploadErrorOpen] = useState(false);
  const [uploadError, setUploadError] = useState('');
  let history = useHistory();
  const recaptchaRef: any = useRef({});
  const graduateForm: any = useRef({});

  const onSubmit = async data => {
    try {
      await new Promise((res, rej) => {
        const fullName = data.first_name + " " + data.last_name;
        if (data.school_name.name === 'Other'){
          data.state = data.other_state.abbreviation;
          data.city = data.other_city.charAt(0).toUpperCase() + data.other_city.slice(1);
          data.school_name = data.school_name.name;
        } else {
          data.state = data.school_name.state;
          data.city = data.school_name.city;
          data.school_name = data.school_name.name;
        }

        data.io_newsletter = data.io_newsletter ? 'yes' : 'no';
        data.xq_newsletter = data.xq_newsletter ? 'yes' : 'no';
        data.release_accepted = data.release_accepted ? 'yes' : 'no';
    
        fetch('https://api.graduatetogetheryearbook.com/verify', {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
        })
        .then(d => d.json())
        .then(response => {
          console.log(response);
          if(response.errors){
            setOpen(true);
            rej();
          } else {
            const { signature, iid, timestamp, metadata } = response;
            const formData = new FormData();
            formData.append('file', data.photo);
            formData.append('public_id', iid);
            formData.append('api_key', '958925455949822');
            formData.append('timestamp', timestamp);
            formData.append('signature', signature);
            formData.append('moderation', 'manual');
            formData.append('tags', 'upload');
            formData.append('metadata', metadata);
      
            fetch('https://api.cloudinary.com/v1_1/graduatetogether/image/upload', {
              method: 'POST',
              body: formData
            })
              .then(r => {
                if(!r.ok){
                  setOpen(true);
                  rej();
                }

                reset({
                  school_name: null,
                  photo: {},
                  "release_accepted": false,
                  "newsletter_accepted": false
                });
      
                setFiles([]);
                setCheck('');
                recaptchaRef.current.execute();
                res();
                history.push({
                  pathname: '/form/success',
                  state: { name: fullName, email: data.email }
                })
              })
              .catch(e => {
                setOpen(true);
                rej();
              });
            }
        }).catch(() => {
          setOpen(true);
          rej();
        })
      })
    } catch (e) {
      setOpen(true);
    }
  };

  const [filter, setCheck] = React.useState('');
  const [ageCheck, setAgeCheck] = React.useState(false);

  const handleCheck = (event) => {
    setCheck(event.target.value);
  };

  const MAX_FILE_SIZE = 10000000;
  let file;

  const [files, setFiles] = useState([]);

  const removeAll = () => {
    setFiles([]);
  }

  const fileUpload = (files) => {
    if (files.length === 0) {
      // console.log('No files found!', 'danger');
      return;
    }
    file = files[0];
    files.splice(1);

    if (file.size > MAX_FILE_SIZE) {
      // console.log('image too large');
      setUploadError('Uploaded Image too large');
      setUploadErrorOpen(true);
      return;
    }

    setFiles(files.map(file => Object.assign(file, {
      preview: URL.createObjectURL(file)
    })));

    setValue("photo", file);
  };

  const thumbs = files.map((file:any) => (
    <div className="thumbnail" key={file.name}>
      <div className="thumbnail-inner">
        <img
          className="thumbnail-image"
          src={file.preview}
          alt="preview"
        />
      </div>
    </div>
  ));

  useEffect(() => () => {
    // Make sure to revoke the data uris to avoid memory leaks
    files.forEach((file: any) => URL.revokeObjectURL(file.preview));
  }, [files]);

  const checkAge = () => {
    var date = new Date(getValues().date_of_birth);
    setAgeCheck(new Date(date.getFullYear() + 18, date.getMonth(), date.getDate() + 1) > new Date());
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setUploadErrorOpen(false);
  };

  const validateCaptcha = (e) => {
    setCaptchaValidating(true);
    e.preventDefault();
    recaptchaRef.current.reset();
    recaptchaRef.current.execute().then(() => {
      setTimeout(function(){ 
        setCaptchaValidating(false); 
      }, 1500);
    });
  }

  const updateCharsLeft = (e) => {
    const re =  /^[ A-Za-zÀ-ÿ0-9_@.“/#?{}$=_<>,'"*&!()+-]*$/;
    if (!re.test(e.key)) {
      e.preventDefault();
    }
    setCharsLeft(getValues().message ? 500 - getValues().message.length : 500);
  }

  const dateFormat = (e) => {
    if(e.which !== 8) { 
      var numChars = getValues().date_of_birth.length;
      if(numChars === 2 || numChars === 5){
          var thisVal = getValues().date_of_birth;
          thisVal += '/';
          setValue("date_of_birth", thisVal);
      }
    }
  }

  return(
    <>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={open}
        message="There are some errors in the form, please review and fix."
      >
        <MuiAlert elevation={6} severity="error" icon={<img src={error} alt="error" />}>There are some errors in the form, please review and fix.</MuiAlert>
      </Snackbar>

      <ImageCarousel />

      <div className="headline">
        <div className="headline-content">
          <h1>SUBMIT YOUR GRADUATE TOGETHER YEARBOOK PHOTO</h1>
        </div>
      </div>
      <form className="container" ref={graduateForm}>
        <div className="form-block">
          <div className="form-info">
            <h3>TELL US ABOUT YOU</h3>
            <p>
              We need some info about you and your high school to start.
            </p>
          </div>
          <div className="form-section">
            <div className='form-container'>

              <div className="form-field">
                <div className="form-label">
                  <h3> First Name </h3>
                </div>
                <div className="form-items">
                  <div className="form-validate">
                    {errors.first_name && <img src={error} alt="error" />}
                    {!errors.first_name && formState.touched.first_name && <img src={check} alt="check" />}
                  </div>
                  <div className="form-input">
                    <div className="message-input">
                      <TextField 
                        id="standard-basic"
                        placeholder="First Name"
                        name="first_name"
                        InputProps={{
                          endAdornment: <div className="form-validate-mobile">
                          {errors.first_name && <img src={error} alt="error" />}
                          {!errors.first_name && formState.touched.first_name && <img src={check} alt="check" />}
                        </div>,
                        }}
                        className={classNames('form-input-field', {
                          'input-field-error': errors.first_name,
                        })}
                        inputRef={register({ required: true, maxLength: 50, minLength: 1})}
                      />
                    </div>
                  </div>
                  <div className="form-errors">
                    {errors.first_name && errors.first_name.type === 'required' && <p className="error-text">First Name is required.</p>}
                    {errors.first_name && errors.first_name.type === 'maxLength' && <p className="error-text">Max length is 50 characters.</p>}
                    {errors.first_name && errors.first_name.type === 'minLength' && <p className="error-text">Max length is 1 character.</p>}
                  </div>
                </div>
              </div>

              <div className="form-field">
                <div className="form-label">
                  <h3> Last Name </h3>
                </div>
                <div className="form-items">
                  <div className="form-validate">
                    {errors.last_name && <img src={error} alt="error" />}
                    {!errors.last_name && formState.touched.last_name && <img src={check} alt="check" />}
                  </div>
                  <div className="form-input">
                    <div className="message-input">
                      <TextField 
                        id="standard-basic"
                        placeholder="Last Name"
                        name="last_name"
                        InputProps={{
                          endAdornment: <div className="form-validate-mobile">
                          {errors.last_name && <img src={error} alt="error" />}
                          {!errors.last_name && formState.touched.last_name && <img src={check} alt="check" />}
                        </div>,
                        }}
                        className={classNames('form-input-field', {
                          'input-field-error': errors.last_name,
                        })}
                        inputRef={register({ required: true, maxLength: 50, minLength: 1})}
                      />
                    </div>
                  </div>
                  <div className="form-errors">
                    {errors.last_name && errors.last_name.type === 'required' && <p className="error-text">Last Name is required.</p>}
                    {errors.last_name && errors.last_name.type === 'maxLength' && <p className="error-text">Max length is 50 characters.</p>}
                  </div>
                </div>
              </div>

              <div className="form-field">
                <div className="form-label">
                  <h3> Date Of Birth </h3>
                </div>
                <div className="form-items">
                  <div className="form-validate">
                    {errors.date_of_birth && <img src={error} alt="error" />}
                    {!errors.date_of_birth && formState.touched.date_of_birth && <img src={check} alt="check" />}
                  </div>
                  <div className="form-input">
                    <div className="message-input">
                      <TextField 
                        id="standard-basic"
                        placeholder="MM / DD / YYYY"
                        name="date_of_birth"
                        type="date"
                        onKeyDown={dateFormat}
                        InputProps={{
                          inputProps: { min: "1900-01-01", max: "2030-12-31", maxLength: 10},
                          endAdornment: <div className="form-validate-mobile">
                          {errors.date_of_birth && <img src={error} alt="error" />}
                          {!errors.date_of_birth && formState.touched.date_of_birth && <img src={check} alt="check" />}
                        </div>,
                        }}
                        onChange={checkAge}
                        className={classNames('form-input-field', {
                          'input-field-error': errors.date_of_birth,
                        })}
                        inputRef={register({ required: true, maxLength: 100, minLength: 1, pattern: {
                          value: /^(19|20)\d\d[- /.](0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])|^(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d$$/i,
                          message: "invalid date of birth"
                        } })}
                      />
                    </div>
                  </div>
                  <div className="form-errors">
                    {errors.date_of_birth && errors.date_of_birth.type === 'required' && <p className="error-text">Date of Birth is required.</p>}
                    {errors.date_of_birth && errors.date_of_birth.type === 'maxLength' && <p className="error-text">Max length is 500 characters.</p>}
                    {errors.date_of_birth && errors.date_of_birth.type === 'pattern' && <p className="error-text">Must be a valid date of birth.</p>}
                  </div>
                </div>
              </div>

              {ageCheck && (
                <div className="form-field">
                  <div className="form-label">
                    <h3> Guardian's Name </h3>
                  </div>
                  <div className="form-items">
                    <div className="form-validate">
                      {errors.release_guardian_name && <img src={error} alt="error" />}
                      {!errors.release_guardian_name && formState.touched.release_guardian_name && <img src={check} alt="check" />}
                    </div>
                    <div className="form-input">
                      <div className="message-input">
                        <TextField 
                          id="standard-basic"
                          placeholder="Guardian's Name"
                          name="release_guardian_name"
                          InputProps={{
                            endAdornment: <div className="form-validate-mobile">
                            {errors.release_guardian_name && <img src={error} alt="error" />}
                            {!errors.release_guardian_name && formState.touched.release_guardian_name && <img src={check} alt="check" />}
                          </div>,
                          }}
                          className={classNames('form-input-field', {
                            'input-field-error': errors.release_guardian_name,
                          })}
                          inputRef={register({ required: true, maxLength: 50, minLength: 1})}
                        />
                      </div>
                    </div>
                    <div className="form-errors">
                      {errors.release_guardian_name && errors.release_guardian_name.type === 'required' && <p className="error-text">Guardian's Name is required.</p>}
                      {errors.release_guardian_name && errors.release_guardian_name.type === 'maxLength' && <p className="error-text">Max length is 50 characters.</p>}
                    </div>
                  </div>
                </div>
              )}

              <div className="form-field">
                <div className="form-label">
                  <h3> Email </h3>
                </div>
                <div className="form-items">
                  <div className="form-validate">
                    {errors.email && <img src={error} alt="error" />}
                    {!errors.email && formState.touched.email && <img src={check} alt="check" />}
                  </div>
                  <div className="form-input">
                    <div className="message-input">
                      <TextField 
                        id="standard-basic"
                        placeholder="Your email address"
                        type="email"
                        name="email"
                        InputProps={{
                          endAdornment: <div className="form-validate-mobile">
                          {errors.email && <img src={error} alt="error" />}
                          {!errors.email && formState.touched.email && <img src={check} alt="check" />}
                        </div>,
                        }}
                        className={classNames('form-input-field', {
                          'input-field-error': errors.email,
                        })}
                        inputRef={register({ required: true, maxLength: 100, minLength: 1, 
                          pattern: {
                            value: /^[a-zA-Z0-9.-_+]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}$/,
                            message: "invalid date of birth"
                          }
                        })}
                      />
                    </div>
                  </div>
                  <div className="form-errors">
                    {errors.email && errors.email.type === 'required' && <p className="error-text">Email is required.</p>}
                    {errors.email && errors.email.type === 'maxLength' && <p className="error-text">Max length is 100 characters.</p>}
                    {errors.email && errors.email.type === 'pattern' && <p className="error-text">Must be a valid email address.</p>}
                  </div>
                </div>
              </div>

              <div className="form-field">
                <div className="form-label">
                  <h3 className="school-label"> Full Name Of Your High School </h3>
                  <p className="school-text">Type using a comma to separate school, city and state</p>
                </div>
                <div className="form-items">
                  <div className="form-validate">
                    {errors.school_name && <img src={error} alt="error" />}
                    {!errors.school_name && formState.touched.school_name && <img src={check} alt="check" />}
                  </div>
                  <div className="form-input">
                    <div className="message-input">
                      <HighSchoolSelect 
                        onChange={([, data]) => data} 
                        control={control} 
                        formStyle="standard"
                        className={classNames('form-input-field', {
                          'input-field-error': errors.school_name,
                        })}
                      />
                    </div>
                  </div>
                  <div className="form-errors">
                    <div className="form-validate-mobile">
                      {errors.school_name && <img src={error} alt="error" />}
                      {!errors.school_name && formState.touched.school_name && <img src={check} alt="check" />}
                    </div>
                    {errors.school_name && errors.school_name.type === 'required' && <p className="error-text">High School is required.</p>}
                  </div>
                </div>
              </div>

              {getValues().school_name && getValues().school_name.name === "Other" && (
              <>
              <div className="form-field">
                <div className="form-label">
                  <h3>Other High School</h3>
                </div>
                <div className="form-items">
                  <div className="form-validate">
                    {errors.other_school_name && <img src={error} alt="error" />}
                    {!errors.other_school_name && formState.touched.other_school_name && <img src={check} alt="check" />}
                  </div>
                  <div className="form-input">
                    <div className="message-input">
                      <TextField 
                        id="standard-basic"
                        placeholder="High School Name"
                        name="other_school_name"
                        InputProps={{
                          endAdornment: <div className="form-validate-mobile">
                          {errors.other_school_name && <img src={error} alt="error" />}
                          {!errors.other_school_name && formState.touched.other_school_name && <img src={check} alt="check" />}
                        </div>,
                        }}
                        className={classNames('form-input-field', {
                          'input-field-error': errors.other_school_name,
                        })}
                        inputRef={register({ required: true, maxLength: 100, minLength: 1})}
                      />
                    </div>
                  </div>
                  <div className="form-errors">
                    <div className="form-validate-mobile">
                      {errors.other_school_name && <img src={error} alt="error" />}
                      {!errors.other_school_name && formState.touched.other_school_name && <img src={check} alt="check" />}
                    </div>
                    {errors.other_school_name && errors.other_school_name.type === 'required' && <p className="error-text">Other High School Name is required.</p>}
                    {errors.other_school_name && errors.other_school_name.type === 'maxLength' && <p className="error-text">Max length is 100 characters.</p>}
                  </div>
                </div>
              </div>

              <div className="form-field">
                <div className="form-label">
                  <h3>City</h3>
                </div>
                <div className="form-items">
                  <div className="form-validate">
                    {errors.other_city && <img src={error} alt="error" />}
                    {!errors.other_city && formState.touched.other_city && <img src={check} alt="check" />}
                  </div>
                  <div className="form-input">
                    <div className="message-input">
                      <TextField 
                        id="standard-basic"
                        placeholder="City"
                        name="other_city"
                        InputProps={{
                          endAdornment: <div className="form-validate-mobile">
                          {errors.other_city && <img src={error} alt="error" />}
                          {!errors.other_city && formState.touched.other_city && <img src={check} alt="check" />}
                        </div>,
                        }}
                        className={classNames('form-input-field', {
                          'input-field-error': errors.other_city,
                        })}
                        inputRef={register({ required: true, maxLength: 100, minLength: 1})}
                      />
                    </div>
                  </div>
                  <div className="form-errors">
                    <div className="form-validate-mobile">
                      {errors.other_city && <img src={error} alt="error" />}
                      {!errors.other_city && formState.touched.other_city && <img src={check} alt="check" />}
                    </div>
                    {errors.other_city && errors.other_city.type === 'required' && <p className="error-text">City is required.</p>}
                    {errors.other_city && errors.other_city.type === 'maxLength' && <p className="error-text">Max length is 100 characters.</p>}
                  </div>
                </div>
              </div>

              <div className="form-field">
                <div className="form-label">
                  <h3>State</h3>
                </div>
                <div className="form-items">
                  <div className="form-validate">
                    {errors.other_state && <img src={error} alt="error" />}
                    {!errors.other_state && formState.touched.other_state && <img src={check} alt="check" />}
                  </div>
                  <div className="form-input">
                    <div className="message-input">
                      <StateSelect 
                        onChange={([, data]) => data} 
                        control={control} 
                        className={classNames('form-input-field', {
                          'input-field-error': errors.other_state,
                        })}
                      />
                    </div>
                  </div>
                  <div className="form-errors">
                    <div className="form-validate-mobile">
                      {errors.other_state && <img src={error} alt="error" />}
                      {!errors.other_state && formState.touched.other_state && <img src={check} alt="check" />}
                    </div>
                    {errors.other_state && errors.other_state.type === 'required' && <p className="error-text">State is required.</p>}
                    {errors.other_state && errors.other_state.type === 'maxLength' && <p className="error-text">Max length is 100 characters.</p>}
                  </div>
                </div>
              </div>
              </>
              )}

            </div>
          </div>
        </div>

        <div className="form-block">
          <div className="form-info">
            <h3>SIGN YOUR RELEASE FORM</h3>
            <p>
              Please sign this release form in order to participate. If you're under the age of 18 you must have your legal guardian sign the release.
            </p>
          </div>
          <div className="form-section">
            <div className='form-container'>

              <div className="form-field">
                <div className="form-label">
                <h3> Release Form </h3>
                </div>
                <div className="form-items">
                  <div className="form-validate">
                    {errors.release_accepted && <img src={error} alt="error" />}
                    {!errors.release_accepted && formState.touched.release_accepted && <img src={check} alt="check" />}
                  </div>
                  <div className="form-input flex-90">
                    <div className='legal'>
                    {!ageCheck && (
                      <p>
                        For good and valuable consideration, the receipt and sufficiency of which is hereby acknowledged, I 
                        <b> {getValues().first_name} {getValues().last_name} </b>, by clicking the “<b>I ACCEPT</b>” link below, irrevocably grant to XQ Institute, Unframed
                        USA LTD and the Inside Out Project (collectively, the “Companies”), their assignees, licensees and
                        designees and to each of their respective subsidiaries, affiliates, successors and assigns, and to other
                        such persons as the any of the Companies may designate (collectively, all of the foregoing with the
                        Companies, the “Company Parties”), the absolute right and permission to use, reproduce, display, print,
                        publish, broadcast, synchronize, telecast, edit, modify, clip, revise, distribute and/or otherwise exploit,
                        each in its sole discretion, in any manner, in whole or in part, modified and/or adapted, alone or
                        accompanied by other material (the “Materials”), and in the advertisements, publicity and promotion
                        thereof, in any media and by any means, in each case, now known or hereinafter known, worldwide, in
                        perpetuity, without any royalties, residuals or other payment whatsoever, the following: (i) the photo(s) I
                        upload (“Photos”); (ii) my likeness, image, appearance, name (including nicknames), biographical
                        information, and other indicia identifying me (“Persona”); and (iii) any quotes, stories, answers, opinions,
                        statements or other written material that I submit (“Written Content” and, together with Photos and
                        Persona, the “Content”).<br/><br/>
                        I hereby waive any rights to approve any Materials, use of the Materials, and/or the use of the Content.
                        Nothing herein will constitute any obligation on the Companies’ part to use the Materials and/or the
                        Content or the rights set forth or granted herein. I agree that all right, title and interest in and to the
                        Materials, including the copyrights therein, shall vest exclusively in the Companies. If and to the extent
                        that the foregoing provisions do not operate to vest fully and effectively in the Companies, all or any of
                        such rights, then I irrevocably grant, vest and assign the Companies with all rights of every kind and
                        nature, now known or hereafter devised in and to the Materials throughout the universe, in perpetuity. I
                        hereby irrevocably make, constitute and appoint the Companies or any of their respective officers or
                        designees my true and lawful attorney-in-fact in the name of each Company to enter into and perform
                        such agreements as may be necessary in order to carry out the terms, covenants and conditions of this
                        Release. I irrevocably waive all rights known as “moral rights” or any similar rights or principles of law
                        which I may now or later have in the Materials.<br/>
                        I warrant and represent that I: (i) have the full right and authority to enter into this Agreement and grant all
                        of the rights and assume all of the obligations set forth herein, and that the use of the Content and the
                        Materials by the Company Parties will not conflict with any obligations I may have with any third party, (ii)
                        will not hold any of the Company Parties responsible for any claim or liability resulting from the use of the
                        Materials and/or the Content in accordance with the terms hereof, including, but not limited to, claims in
                        the nature of violation of the right of privacy or publicity, defamation, disparagement, slander, false light,
                        false endorsement or the like, (iii) no third-party consent is required to use the Materials or the Content as
                        permitted hereunder; and (iv) will not object to, interfere with, seek injunctive or other equitable relief, or
                        otherwise inhibit use of the Materials and/or the Content by any of the Company Parties.<br/>
                        I acknowledge and agree that my participation in activities related to the Rethink Together or [Celebrating
                        the Class of 2020] initiative is completely voluntary and shall not constitute me as an employee of the
                        Companies, any Company Party, or any of their vendors or agents, for any purpose whatsoever.<br/>
                        I have carefully and thoroughly read, understood and approved this Agreement before executing it, and I
                        agree to be bound by all its provisions. I understand that the Companies are relying on the foregoing
                        permission and release, and will incur substantial expense in reliance thereon. This Agreement contains
                        the entire understanding between the parties regarding the subject matter hereof and supersedes all prior
                        understandings. No waiver, modification or additions to this Agreement shall be valid unless in writing
                        and signed by both parties hereto. This Agreement shall be governed by the laws of the State of
                        California without regard to its choice of law and/or conflict of law principles and each party submits to the
                        exclusive jurisdiction and venue of an appropriate state or federal court sitting in Los Angeles County in
                        the State of California for any action or proceeding arising out of or relating to this Agreement.<br/>
                        Full Name: <b>{getValues().first_name} {getValues().last_name}</b>, born on <b>{getValues().date_of_birth} </b><br/>
                        E-Mail Address: <b>{getValues().email}</b>
                      </p>
                      )}
                      {ageCheck && (
                        <p>
                        <b>Being {getValues().first_name} {getValues().last_name} under 18 years of age (“Minor”), I, {getValues().release_guardian_name}</b> hereby represent
                        that I am his/her parent or legal guardian. I have read this Agreement thoroughly and understand it and
                        consent and agree to its terms on behalf of myself and the Minor. This document shall be binding upon
                        me and the Minor.<br/><br/>
                        For good and valuable consideration, the receipt and sufficiency of which is hereby acknowledged, I, by
                        clicking the “I ACCEPT” link below, irrevocably grant to XQ Institute, Unframed USA LTD and the Inside
                        Out Project (collectively, the “Companies”), their assignees, licensees and designees and to each of their
                        respective subsidiaries, affiliates, successors and assigns, and to other such persons as the any of the
                        Companies may designate (collectively, all of the foregoing with the Companies, the “Company Parties”),
                        the absolute right and permission to use, reproduce, display, print, publish, broadcast, synchronize,
                        telecast, edit, modify, clip, revise, distribute and/or otherwise exploit, each in its sole discretion, in any
                        manner, in whole or in part, modified and/or adapted, alone or accompanied by other material (the
                        “Materials”), and in the advertisements, publicity and promotion thereof, in any media and by any means,
                        in each case, now known or hereinafter known, worldwide, in perpetuity, without any royalties, residuals
                        or other payment whatsoever, the following: (i) the photo(s) I upload (“Photos”); (ii) my likeness, image,
                        appearance, name (including nicknames), biographical information, and other indicia identifying me
                        (“Persona”); and (iii) any quotes, stories, answers, opinions, statements or other written material that I
                        submit (“Written Content” and, together with Photos and Persona, the “Content”).<br/><br/>
                        I hereby waive any rights to approve any Materials, use of the Materials, and/or the use of the Content.
                        Nothing herein will constitute any obligation on the Companies’ part to use the Materials and/or the
                        Content or the rights set forth or granted herein. I agree that all right, title and interest in and to the
                        Materials, including the copyrights therein, shall vest exclusively in the Companies. If and to the extent
                        that the foregoing provisions do not operate to vest fully and effectively in the Companies, all or any of
                        such rights, then I irrevocably grant, vest and assign the Companies with all rights of every kind and
                        nature, now known or hereafter devised in and to the Materials throughout the universe, in perpetuity. I
                        hereby irrevocably make, constitute and appoint the Companies or any of their respective officers or
                        designees my true and lawful attorney-in-fact in the name of each Company to enter into and perform
                        such agreements as may be necessary in order to carry out the terms, covenants and conditions of this
                        Release. I irrevocably waive all rights known as “moral rights” or any similar rights or principles of law
                        which I may now or later have in the Materials.<br/><br/>
                        I warrant and represent that I: (i) have the full right and authority to enter into this Agreement and grant all
                        of the rights and assume all of the obligations set forth herein, and that the use of the Content and the
                        Materials by the Company Parties will not conflict with any obligations I may have with any third party, (ii)
                        will not hold any of the Company Parties responsible for any claim or liability resulting from the use of the
                        Materials and/or the Content in accordance with the terms hereof, including, but not limited to, claims in
                        the nature of violation of the right of privacy or publicity, defamation, disparagement, slander, false light,
                        false endorsement or the like, (iii) no third-party consent is required to use the Materials or the Content as
                        permitted hereunder; and (iv) will not object to, interfere with, seek injunctive or other equitable relief, or
                        otherwise inhibit use of the Materials and/or the Content by any of the Company Parties.<br/><br/>
                        I acknowledge and agree that my participation in activities related to the Rethink Together or [Celebrating
                        the Class of 2020] initiative is completely voluntary and shall not constitute me as an employee of the
                        Companies, any Company Party, or any of their vendors or agents, for any purpose whatsoever.<br/><br/>
                        I have carefully and thoroughly read, understood and approved this Agreement before executing it, and I
                        agree to be bound by all its provisions. I understand that the Companies are relying on the foregoing
                        permission and release, and will incur substantial expense in reliance thereon. This Agreement contains
                        the entire understanding between the parties regarding the subject matter hereof and supersedes all prior
                        understandings. No waiver, modification or additions to this Agreement shall be valid unless in writing
                        and signed by both parties hereto. This Agreement shall be governed by the laws of the State of
                        California without regard to its choice of law and/or conflict of law principles and each party submits to the
                        exclusive jurisdiction and venue of an appropriate state or federal court sitting in Los Angeles County in
                        the State of California for any action or proceeding arising out of or relating to this Agreement.<br/><br/>
                        Guardian Name: <b>{getValues().release_guardian_name}</b><br/>
                        Full Name: <b>{getValues().first_name} {getValues().last_name}</b>, born on <b>{getValues().date_of_birth}</b><br/>
                        Email: <b>{getValues().email}</b><br/>
                      </p>
                      )}
                    </div>
                    <div className='legal-checkbox'>
                      <p>
                        I have read the <b>Release Form</b>,
                        {' '}
                        <b><a href='/privacy' target="_blank">Privacy Policy</a></b>
                        {' '}
                        and the <b><a href='/terms' target="_blank">Terms of Service</a></b>.
                      </p>
                      <Controller
                        as={<Checkbox disableRipple name="legalCheckbox"/>}
                        name="release_accepted"
                        control={control}
                        defaultValue={false}
                        onClick={() => { triggerValidation("release_accepted")}}
                        inputRef={register({ required: true})}
                      />
                      <label htmlFor='accept' className='accept-label'> - I ACCEPT </label>
                    </div>
                  </div>
                  <div className="form-errors d-none">
                    <div className="form-validate-mobile">
                      {errors.release_accepted && <img src={error} alt="error" />}
                      {!errors.release_accepted && formState.touched.release_accepted && <img src={check} alt="check" />}
                    </div>
                    {errors.release_accepted && errors.release_accepted.type === 'required' && <p className="error-text">Release Form is required.</p>}
                    {errors.release_accepted && errors.release_accepted.type === 'maxLength' && <p className="error-text">Max length is 500 characters.</p>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="form-block">
          <div className="form-info">
            <h3>UPLOAD YOUR PHOTO</h3>
            <p>
              Upload your Graduate Together Yearbook photo now.
            </p>
          </div>
          <div className="form-section">
            <div className='form-container'>
              <div className="form-field">
                <div className="form-label">
                  <h3> Did you take your yearbook photo using the Graduate Together Lens from Snapchat? </h3>
                    <p>If not, <a rel="noopener noreferrer" target="_blank" href="https://www.snapchat.com/unlock/?type=SNAPCODE&uuid=ee85aa5cade749bca9d796f12f1f3eef&metadata=01">click here</a> to access the lens and retake your yearbook photo.</p>
                    <RadioGroup className="filter-radio" aria-label="filter" name="filter" value={getValues().filter} onChange={handleCheck}>
                      <FormControlLabel value="yes" checked={filter === 'yes'} control={<Checkbox disableRipple />} onChange={handleCheck} label="- Yes" />
                      <FormControlLabel value="no" checked={filter === 'no'} control={<Checkbox disableRipple />} onChange={handleCheck} label="- No" />
                    </RadioGroup>
                </div>
                <div className="form-items">
                  <div className="form-validate">
                    {errors.photo && <img src={error} alt="error" />}
                    {!errors.photo && formState.touched.photo && <img src={check} alt="check" />}
                  </div>
                  <div className="form-input">
                    <Snackbar
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}
                      open={uploadErrorOpen}
                      autoHideDuration={6000}
                      onClose={handleClose}
                      message={uploadError}
                    >
                      <MuiAlert elevation={6} severity="error" icon={<img src={error} alt="error" />}>{uploadError}</MuiAlert>
                    </Snackbar>
                    
                    {filter === 'yes' && 
                      <div className='upload-container'>
                        <div className='upload-instruction-mob'>
                          <div className='photo-instruction'>
                            <div className='photo-container bad-example'>
                              <img className='photo' src={badExampleMob} alt='bad-example'/>
                            </div>
                            <div>
                              <p className='good'>Great picture</p>
                              <p className='good'>Good light</p>
                              <p className='bad'>Inside Out lens</p>
                            </div>
                          </div>
                          <div className='photo-instruction'>
                            <div className='photo-container good-example'>
                              <img className='photo' src={goodExampleMob} alt='good-example'/>
                            </div>
                            <div>
                              <p className='good'>Great picture</p>
                              <p className='good'>Good light</p>
                              <p className='good'>Inside Out lens</p>
                            </div>
                          </div>
                        </div>
                        <Controller
                          as={<Dropzone onDrop={fileUpload}>
                            {({getRootProps, getInputProps}) => (
                              <>
                                <section>
                                  <div className="photo-drop" {...getRootProps()}>
                                    <input
                                      {...getInputProps()}
                                      accept="image/png, image/jpeg"
                                    />
                                    <img
                                      src={upload}
                                      alt="upload"
                                      className={classNames({
                                        'hide-div': files.length > 0,
                                      })}
                                    />
                                    <div
                                      className={classNames('upload-text', {
                                        'hide-div': files.length > 0,
                                      })}
                                    >
                                      <p><b>Choose file or drag here</b></p>
                                      <p>Size limit: 10MB</p>
                                    </div>
                                    <aside className="thumbnail-container">
                                      {thumbs}
                                      {files.length > 0 && <p className="bm-cross-button" onClick={removeAll}><b>X</b></p>}
                                    </aside>
                                  </div>
                                </section>
                              </>
                            )}
                          </Dropzone>}
                          name="photo"
                          control={control}
                          defaultValue={''}
                          register={register({ required: true})}
                        />
                        <div className='upload-instruction'>
                          <div className='photo-instruction'>
                            <div className='photo-container bad-example'>
                              <img className='photo' src={badExample} alt='bad-example'/>
                            </div>
                            <div>
                              <p className='good'>Great picture</p>
                              <p className='good'>Good light</p>
                              <p className='bad'>Inside Out lens</p>
                            </div>
                          </div>
                          <div className='photo-instruction'>
                            <div className='photo-container good-example'>
                              <img className='photo' src={goodExample} alt='good-example'/>
                            </div>
                            <div>
                              <p className='good'>Great picture</p>
                              <p className='good'>Good light</p>
                              <p className='good'>Inside Out lens</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                    {filter === 'no' && 
                    <div className="filter-error">
                      <div className="filter-error-icon">
                        <img src={error} alt="error" />
                      </div>
                      <div className="filter-error-text">
                        <p><b>Sorry, only photos taken with the Graduate Together lens can be accepted!</b></p>
                        <p><a rel="noopener noreferrer" target="_blank" href="https://www.snapchat.com/unlock/?type=SNAPCODE&uuid=ee85aa5cade749bca9d796f12f1f3eef&metadata=01">Click this link</a> (or snap to unlock) to access the Graduate Together Lens from Snapchat. Retake your yearbook photo and try again.</p>
                        <img className="snapchat-icon" src={snapchat} alt="snapchat logo" />
                      </div>
                    </div>
                    }
                  </div>
                  {errors.photo && errors.photo.type === 'required' && <p className="error-text">Picture is required.</p>}
                </div>
              </div>

            </div>
          </div>
        </div>

        <div className="form-block">
          <div className="form-info">
            <h3>WRITE YOUR MESSAGE</h3>
            <p>
              Write your message for the Class of 2020, and remember:
            </p>
            <ul>
              <li>Keep it short</li>
              <li>Keep it nice</li>
              <li>Keep it positive</li>
            </ul>

          </div>
          <div className="form-section">
            <div className='form-container'>

              <div className="form-field">
                <div className="form-label">
                  <div className='message-title'>
                    <h3>Write your message for the Class Of 2020</h3>
                    <p 
                      className={classNames({
                        'no-chars': charsLeft === 0
                      })}
                    >
                      {charsLeft} characters left
                    </p>
                  </div>
                </div>
                <div className="form-items">
                  <div className="form-validate">
                    {errors.message && <img src={error} alt="error" />}
                    {!errors.message && formState.touched.message && <img src={check} alt="check" />}
                  </div>
                  <div className="form-input flex-90">
                    <div className="message-input">
                      <textarea
                        placeholder='Your message'
                        maxLength={500}
                        onKeyDown={updateCharsLeft}
                        onChange={updateCharsLeft}
                        className={classNames('form-field', {
                          'field-error': errors.message,
                        })}
                        name="message" 
                        ref={register({ required: true, maxLength: 500, minLength: 1, pattern: {
                          value: /^[ A-Za-zÀ-ÿ0-9_@.“/#?{}$=_<>‘,'"*&!()+-]*$/,
                          message: "no special characters"
                        }})}
                      />
                    </div>
                  </div>
                  <div className="form-errors d-none">
                    <div className="form-validate-mobile">
                      {errors.message && <img src={error} alt="error" />}
                      {!errors.message && formState.touched.message && <img src={check} alt="check" />}
                    </div>
                    {errors.message && errors.message.type === 'required' && <p className="error-text">Message is required.</p>}
                    {errors.message && errors.message.type === 'maxLength' && <p className="error-text">Max length is 500 characters.</p>}
                    {errors.message && errors.message.type === 'pattern' && <p className="error-text">No special characters allowed.</p>}
                  </div>
                </div>
              </div>


              <div className="form-field">
                <div className="form-label">
                  <h3> Stay Updated </h3>
                </div>
                <div className="form-items">
                  <div className="form-validate">

                  </div>
                  <div className="form-input">
                    <div className="newsletter-input">
                      <Controller
                        as={<Checkbox disableRipple name="io_newsletter" />}
                        control={control}
                        defaultValue={false}
                        name="io_newsletter" 
                      />
                      <label htmlFor='io_newsletter' className='newsletter-label'> I’d like to receive updates from the Inside Out Project. </label>
                    </div>

                    <div className="newsletter-input">
                      <Controller
                        as={<Checkbox disableRipple name="xq_newsletter" />}
                        control={control}
                        defaultValue={false}
                        name="xq_newsletter" 
                      />
                      <label htmlFor='xq_newsletter' className='newsletter-label'> I’d like to receive updates from XQ on school culture, design, student voice, and more. </label>
                    </div>
                  </div>
                </div>
              </div>
              

            </div>
          </div>
        </div>

        <div className="submit-btn-container form-block">
          <ReCAPTCHA
            sitekey="6LcJ7fMUAAAAABwTVNDUZQ-Ggz1pbX4TsOuxZORf"
            size="invisible"
            ref={recaptchaRef}
            onChange={() => {
              handleSubmit(onSubmit)();
            }}
          />
          <button 
            onClick={validateCaptcha}
            disabled
            className={classNames('submit-button', {
              'invalid-submit': !formState.isValid || isSubmitting || captchaValidating,
            })}
            value="SUBMIT YOUR PORTRAIT"
          >
            COMPLETE YOUR SUBMISSION
          </button>

          {!formState.isValid && <p>Some fields are not completed, review them and submit the form</p>}

        </div>
        
      </form>
    </>
  )
};

export default Form;
