import React, { useState } from 'react';
import './Header.scss';
import insideout from '../../assets/inside-out.png';
import { slide as Menu } from 'react-burger-menu';
import { Link } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import classNames from 'classnames';

const Header = () => {
  const [menuOpenState, setMenuOpenState] = useState(false);
  const handleNav = () => {
    setMenuOpenState(false);
  }

  let history = useHistory();
  const pathName = history.location.pathname;

  return (
    <div className="header">
      <div className="header-content">
        <div className="left-content">
          <div className="logo">
            <Link to="/"><img src={insideout} className="inside-out-logo" alt="inside out logo" /></Link>
          </div>
          <div className="header-text">
            <Link to="/">Graduate Together Yearbook</Link>
          </div>
        </div>
        <div className="right-content">
          <div className="menu">
            <Link to="/about" 
              className={classNames('feed-item', {
                'active': pathName === '/about',
              })}
            >
              ABOUT
            </Link>
            <Link to="/yearbook"
              className={classNames('feed-item', {
                'active': pathName === '/yearbook',
              })}
            >YEARBOOK</Link>
            <Link to="/map"
                  className={classNames('feed-item', {
                    'active': pathName === '/map',
                  })}
            >MAP</Link>
            <Link to="/gallery"
              className={classNames('feed-item', {
                'active': pathName === '/gallery',
              })}
            >GALLERY</Link>
          </div>

          <div className="hamburger">
            <Menu right disableAutoFocus pageWrapId={ "app-container" } width={ '280px' } outerContainerId={ "root" } isOpen={menuOpenState} onStateChange={handleNav}>
              <a id="about" className="menu-item" href="/about">ABOUT</a>
              <a id="yearbook" className="menu-item" href="/yearbook">YEARBOOK</a>
              <a id="map" className="menu-item" href="/map">MAP</a>
              <a id="gallery" className="menu-item" href="/gallery">GALLERY</a>
            </Menu>
          </div>
        </div>
      </div>
    </div>
    
  );
}

export default Header;
