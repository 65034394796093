import React, { useEffect } from 'react';
import './Form.scss';
import facebook from '../../assets/facebook.png';
import twitter from '../../assets/twitter.png';
import { withRouter } from 'react-router-dom';
import ImageCarousel from '../ImageCarousel/ImageCarousel';

const FormSuccess = withRouter(({ history }) => {

  const openFacebookShare = () => {
    window.open(
      'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent("https://www.graduatetogetheryearbook.com/"),
      'facebook-share-dialog',
      'width=626,height=436'
    );
  }

  useEffect(() => {
    const script = document.createElement('script');
  
    script.innerHTML = `(function(e,t,n){if(e.snaptr)return;var a=e.snaptr=function()
      {a.handleRequest?a.handleRequest.apply(a,arguments):a.queue.push(arguments)};
      a.queue=[];var s='script';r=t.createElement(s);r.async=!0;
      r.src=n;var u=t.getElementsByTagName(s)[0];
      u.parentNode.insertBefore(r,u);})(window,document,
      'https://sc-static.net/scevent.min.js');
      
      snaptr('init', 'f27b48d7-9117-4936-b497-ce8efcaa281c', {
      'user_email': '${history.location.state.email}'
      });
      
      snaptr('track', 'PAGE_VIEW');`;
    script.async = true;
  
    document.body.appendChild(script);
  
    return () => {
      document.body.removeChild(script);
    }
  }, []);

  return(
    <>
      <div className="form-success">
        <div className="headline">
          <div className="headline-content">
            <h1>Thank you, {history.location.state.name}!</h1>
          </div>
          <p className="success-text">
            We have your submission but need to validate it, first. Once we confirm it meets the requirements, 
            you'll be featured in the Graduate Together Yearbook honoring the high school Class of 2020!
          </p>
          <div className="social-share">
            <p>SHARE</p>
            <a rel="noopener noreferrer" target="_blank" href="https://twitter.com/intent/tweet?text=%23GraduateTogetherYearbook%20Your%20Yearbook%20just%20got%20bigger%21%0A%0ANow%E2%80%99s%20your%20chance%20to%20get%20your%20portrait%20in%20the%20largest%20yearbook%2C%20ever.%20A%20nationwide%20initiative%20that%20celebrates%20every%20single%20graduating%20high%20school%20senior%20in%20the%20country.%0A%0AGraduateTogetherYearbook.com">
              <img className="twitter-icon" src={twitter} alt="share to twitter" />
            </a>
            <img onClick={openFacebookShare} className="facebook-icon" src={facebook} alt="share to facebook" />
          </div>
        </div>
      </div>
      <ImageCarousel />
    </>
  )
});

export default FormSuccess;
