import React, {useState} from 'react';
import './StudentDialog.scss';
import Dialog from '@material-ui/core/Dialog';
import cross from '../../assets/cross.svg';
import arrowRight from '../../assets/arrow-right.svg';
import arrowLeft from '../../assets/arrow-left.svg';
import download from '../../assets/download.svg';
import twitter from '../../assets/twitter.svg';
import facebook from '../../assets/facebook.svg';
import downloadWhite from '../../assets/downloadWhite.svg';
import twitterWhite from '../../assets/twitterWhite.svg';
import facebookWhite from '../../assets/facebookWhite.svg';
import zoomIn from '../../assets/zoom-in.svg';
import classNames from 'classnames';
import { saveAs } from 'file-saver';
import { useSwipeable } from "react-swipeable";

const StudentDialog = (props) => {
  const { onClose, onNext, onPrevious, selectedValue, open } = props;
  let [zoomed, setZoomed] = useState(false);

  const handleClose = () => {
    if(zoomed) {
      setZoomed(false);
    } else {
      setZoomed(false);
      onClose(selectedValue);
    }
  };

  const nextItem = () => {
    if (!zoomed) {
      onNext();
    }
  };

  const previousItem = () => {
    if (!zoomed) {
      onPrevious();
    }
  };

  const downloadImage = (imgUrl) => {
    saveAs("https://res.cloudinary.com/graduatetogether/image/upload/t_GT%20Web,fl_attachment/v1589396769/" + selectedValue.image_id + ".jpg", "graduate-together.jpg");
  }

  const openFacebookShare = () => {
    window.open(
      'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent("https://www.graduatetogetheryearbook.com/yearbook/" + selectedValue.state + "/" + selectedValue.city + "/" + selectedValue.school_name + "/" + selectedValue.id),
      'facebook-share-dialog',
      'width=626,height=436'
    );
  }

  const onKeyDown = (e) => {
    if (e.keyCode === 37) {
      previousItem();
    } else if (e.keyCode === 39) {
      nextItem();
    }
  }

  const handlers = useSwipeable({
    onSwipedLeft: () => nextItem(),
    onSwipedRight: () => previousItem(),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  });

  const zoom = () => {
    setZoomed(true);
  }

  return (
    <>
    {open && (
      <>
        <img onClick={handleClose} alt="cross" className="close-button" src={cross} />
        {!zoomed && (
          <>
          <img onClick={nextItem} alt="arrow right" className="arrow-right" src={arrowRight} />
          <img onClick={previousItem} alt="arrow left" className="arrow-left" src={arrowLeft} />
          </>
        )}
        {zoomed && (
          <div className="zoomed-social-buttons">
            <img
              alt="download"
              onClick={() => 
                downloadImage("https://images.graduatetogetheryearbook.com/graduatetogether/image/upload/t_GT%20Web/v1589396769/" + selectedValue.image_id + ".jpg")}
              className="download"
              src={downloadWhite}
            />
            <a 
              rel="noopener noreferrer" 
              target="_blank" 
              href={"https://twitter.com/intent/tweet?text=https://graduatetogetheryearbook.complex.com/yearbook/" + selectedValue.state + "/" + selectedValue.city + "/" + selectedValue.school_name + "/" + selectedValue.id}
            >
              <img className="twitter" alt="twitter" src={twitterWhite} />
            </a>
            <img onClick={openFacebookShare} alt="facebook" className="facebook" src={facebookWhite} />
          </div>
        )}
      </>
    )}
    <Dialog 
      {...handlers} 
      onClose={handleClose}
      onKeyDown={onKeyDown}
      maxWidth="lg"
      aria-labelledby="simple-dialog-title"
      open={open}
      className={classNames('filter-button', {
        'zoomed-dialog': zoomed,
      })}
    >
      {selectedValue && 
      <>
      <div className="dialog-container">
        {!zoomed && (
          <>
          <div className="student-image">
            <img 
              className="yearbook-image"
              alt="large student"
              src={"https://images.graduatetogetheryearbook.com/graduatetogether/image/upload/t_GT%20Web/v1589396769/" + selectedValue.image_id + ".jpg"}
            />
            <div onClick={zoom} className="mobile-image-container">
              <img 
                className="yearbook-image-mobile"
                alt="small student"
                src={"https://images.graduatetogetheryearbook.com/graduatetogether/image/upload/t_GT_web_zoom_1/v1589396769/" + selectedValue.image_id + ".jpg"}
              />
              <div className="zoom-container">
                <img alt="zoom in" className="zoom-button" src={zoomIn} />
              </div>
            </div>
            <div className="student-info-mobile">
              <h2 className="student-name">
                {selectedValue.first_name} {selectedValue.last_name.charAt(0)}.
              </h2>
              <p className="school-name">{selectedValue.school_name.replace(/&amp;/g, '&')}, {selectedValue.state}</p>
            </div>
          </div>
          <div className="student-info">
            <h2 className="student-name">
              {selectedValue.first_name} {selectedValue.last_name.charAt(0)}.
            </h2>
            <p className="school-name">{selectedValue.school_name.replace(/&amp;/g, '&')}, {selectedValue.state}</p>
            <p className="message">
              {
                selectedValue.message
                  .replace(/\\/g, '')
                  .replace(/&quot;/g, '"')
                  .replace(/&amp;/g, '&')
                  .replace(/&lt;/g, '<')
              }
            </p>
            <div className="social-buttons">
              <img
                alt="download"
                onClick={() => 
                  downloadImage("https://images.graduatetogetheryearbook.com/graduatetogether/image/upload/t_GT%20Web/v1589396769/" + selectedValue.image_id + ".jpg")}
                className="download"
                src={download}
              />
              <a 
                rel="noopener noreferrer" 
                target="_blank" 
                href={"https://twitter.com/intent/tweet?text=https://graduatetogetheryearbook.complex.com/yearbook/" + selectedValue.state + "/" + selectedValue.city + "/" + selectedValue.school_name + "/" + selectedValue.id}
              >
                <img className="twitter" alt="twitter" src={twitter} />
              </a>
              <img onClick={openFacebookShare} alt="facebook" className="facebook" src={facebook} />
            </div>
          </div>
          </>
        )}
        {zoomed && (
          <div className="zoomed">
            <img 
              className="zoomed-student"
              alt="zoomed student"
              src={"https://images.graduatetogetheryearbook.com/graduatetogether/image/upload/t_GT%20Web/v1589396769/" + selectedValue.image_id + ".jpg"}
            />
          </div>
        )}
      </div>
      </>
      }
    </Dialog>
    </>
  );
}

export default StudentDialog;