import React from 'react';
import './Terms.scss';
import { Link } from 'react-router-dom';

const Terms = () => {
  return (
    <div className="terms">
      <div className="title">
        <h1>TERMS OF USE AGREEMENT</h1>
      </div>

      <div className="terms-content">
        <h2>THANK YOU FOR VISITING THE GRADUATE TOGETHER YEARBOOK WEBSITE!</h2>
        <p>
        This Terms of Use Agreement (“Agreement”) constitutes a legally binding contract between Unframed USA Ltd. and its project, 
        the Inside Out Project, (collectively, “Unframed,” “we,” “us,” “our”), XQ Institute (“XQ”), and you with respect to your use 
        of the website for the Graduate Together Yearbook initiative (the “Initiative”), which website is located 
        at https://www.graduatetogetheryearbook.com/ and all Initiative associated sites linked to or associated 
        with https://www.graduatetogetheryearbook.com/ (including any mobile versions thereof) and the Information and 
        Services (each as defined below) (collectively, the “Applications”).  It is important that you carefully read and 
        understand the terms and conditions of this Agreement.  The Applications are the property of Unframed and XQ (as applicable) 
        and each of its licensors.
        </p>
        <p>
        Access to certain portions or functionalities of the Applications will require you to provide certain information to Unframed 
        and/or XQ.  By using the Applications or any similar mechanism, you are consenting to be bound by the terms and conditions 
        of this Agreement.  BY USING THE APPLICATIONS, YOU AGREE TO THE TERMS OF USE IN THIS AGREEMENT; IF YOU DO NOT AGREE TO THESE 
        TERMS AND CONDITIONS, PLEASEDO NOT ACCESS OR USE ANY OF THE APPLICATIONS..
        </p>
        <p>If you access or use the Applications on behalf of any company, partnership, or other entity, you represent that you have authority 
          to bind such entity and its affiliates to this Agreement and that it is fully binding on such entity.  The term “you,” as used in 
          this Agreement, will refer to such entity and its affiliates.  If you do not have authority to bind such entity, you may not access 
          or use the Applications.</p>

        <p>We and/or XQ (as applicable) reserve the right at any time, with or without cause, to:</p>
        <p>a. change the terms and conditions of this Agreement, provided that notice of change shall be posted with 
          the new terms and conditions of this Agreement;</p>
        <p>
          b. change the Applications, including eliminating or discontinuing any Information or Services or other feature of the Applications; and
        </p>

        <p>
          c. deny or terminate your use of and/or access to the Applications
        </p>
        <p>
          Any changes we and/or XQ (as applicable) make will be effective immediately upon our making such changes available on the Applications 
          or otherwise providing notice thereof.  You agree thatyour continued use of the Applications after such changes constitutes your 
          acceptance of such changes.  Be sure to return to this page periodically to ensure familiarity with the most current version of this Agreement
        </p>

        <p className="terms-header">PERMITTED USE OF SERVICES AND INFORMATION</p>
        <p>
        The services made available on, by or through the Applications (collectively, the “Services”) as well as any information provided 
        on, by or through the Applications or as part of or in connection with the Services or otherwise (collectively, the “Information”), 
        are provided for personal use only.  You are permitted to view and print a reasonable number of copies of webpages located on the 
        Applications for your own personal use, but not for any for-profit or commercial purpose or for resale, provided that (a) you 
        retain all trademark, copyright, and otherproprietary notices contained in the original materials, (b) you provide attribution 
        to the Initiative, and (c) any such copies are subject to the terms and conditions of this Agreement.  Youunderstand and agree 
        that you may not authorize any Information to be reproduced, modified, displayed, performed, transferred, distributed or otherwise 
        used by any third party, and you agreethat you will take all reasonable steps to prevent any unauthorized reproduction and/or other 
        use of the Information.  You agree to advise Unframed promptly of any such unauthorized use of which you are aware.  Failure to abide 
        by these conditions will immediately terminate this permission and may result in the infringement of the copyrights and/or trademarks 
        and other proprietary rights of Unframed, XQ or others. 
        </p>

        <p>
        If you are a governmental or nonprofit entity, or an employee thereof, you may download, copy, distribute and use the available 
        Information solely for the purpose of furthering your organization’s mission in the ordinary course of your governmental or 
        charitable operations so long as such use is for non-commercial purposes and made available to third parties without a fee, 
        and provided that you comply with the conditions noted in (a) - (c) above in this section.
        </p>

        <p className="terms-header">SUBMISSIONS</p>
        <p>
        As part of your use of the Applications or the Services, you may participate in certain image and content submission activities. 
        Your participation is voluntary; however, by choosing to submit any Content (as defined below), or otherwise participate in any 
        Application or Service, you acknowledge and agree that, subject to the Privacy Policy (as defined below) and any release youagree 
        to in association with the submission of such Content (your “Release”), such Content may be viewed by Unframed and XQ and may 
        otherwise be used in accordance with your Release.  
        </p>

        <p>
        “Content” means, collectively, the following: (i) any photo(s) you upload to the Application; (ii) your likeness, image, 
        appearance, name (including nicknames), biographical information, and other indicia identifying you; (iii) any quotes, stories, 
        answers, opinions, statements or other written material that you submit to the Application; and (iv) any other information or 
        content yousubmit or provide during your use of the Applications or the Services . 
        </p>

        <p>
        Unframed and XQ are prohibited by their tax-exempt status from (a) engaging in any legislative lobbying activity, 
        (b) promoting or opposing anyone’s candidacy in any election to public office or otherwise supporting or opposing any 
        candidate or political party, and (c) conducting any voter registration drive unless certain conditions are met; you agree 
        that you will not use the Applications in a manner that may cause XQ and/or Unframed to violate any of these prohibitions.
        </p>

        <p>
        Either Unframed or XQ may aggregate Content with other data and also collect technical information and data about your 
        use of the Applications.  You expressly agree that Unframed or XQ may use any aggregated and anonymized data for any 
        purpose during or after the term of thisAgreement, including, without limitation, to develop and improve the Applications 
        or otherwise develop and improve the Initiative’s services and products
        </p>

        <p className="terms-header">THIRD-PARTY LINKS</p>
        <p>
        The Applications may contain links to other websites, databases, networks, servers, information, software, programs, 
        systems, directories, applications, products or services (collectively, “External Websites”) for your convenience. 
        We do not control the External Websites or the content provided through such websites, and we have not reviewed, in their entirety, 
        such websites.  Neither Unframed nor XQ is and cannot be responsible for the External Websites’ content, operation or use. By 
        linking or otherwise providing access to any External Websites, Unframed does not give any representation, warranty or endorsement, 
        express or implied, with respect to the legality, accuracy, quality or authenticity of content, information or services 
        provided by such websites.  The fact that we offer such links does not indicate any approval or endorsement by us of any 
        External Websites or any material contained on any External Website, and we disclaim any such approval or endorsement. 
        Your use of External Websites is subject to the privacy practices and terms of use established by the specific linked website, 
        and we disclaimall liability for such use.  You are solely responsible for reviewing any terms of use, privacy policy or other 
        terms governing your use of these External Websites, which you use at your own risk.  You are advised to make reasonable inquiries 
        before entering into any transaction, financialor otherwise, and whether online or offline, with any third party related to any 
        External Websites.When using External Websites, you are solely responsible for protecting yourself from fraud and for protecting 
        your computer systems from viruses, worms, Trojan horses, and other harmful or destructive content and material that may be included 
        on or may emanate from any External Websites.
        </p>

        <p className="terms-header">PERMISSIONS FOR MOBILE DEVICE USERS</p>
        <p>
        If your use of the Applications includes use through a mobile device, you hereby consent to and grant us permission 
        to access (but not store or transfer, unless separately authorized by you) photos on your mobile device (including metadata) 
        in order to enable tagging (e.g., date, location) of your posted Content.
        </p>

        <p className="terms-header">INITIATIVE COMMUNICATIONS</p>
        <p>
        You agree that Unframed or XQ may send you communications (via email or through the Applications, including SMS 
        text messages if you have opted to receive text messages) regardingyour use of the Applications and/or certain features of the 
        Applications you may be interested in. To the extent you have opted to receive communications, updates and newsletters, you hereby 
        authorize Unframed or XQ, or those engaged on Unframed’s or XQ’s behalf (as applicable), to deliver such communications to you via 
        email, SMS text messages, or through the Applications.
        </p>

        <p className="terms-header">PRIVACY POLICY</p>
        <p>
        You understand that your use of the Applications shall be subject to the privacy policy (the “Privacy Policy”) 
        located here: <Link to="/privacy">https://www.graduatetogetheryearbook.com/privacy</Link>.  Unframed reserves the right to modify the Privacy Policy in its discretion from time to time.  
        Continued use of the Applications after any such changes shall constitute your consent to such changes.  By providing Unframed 
        with your email address or other contact information, you consent to our use of this information to send you Applications-related 
        notices and other administrative notices, including any notices required by law.
        </p>

        <p className="terms-header">ACCEPTABLE USE POLICY</p>
        <p>
        You agree not to use the Information, Services or the Applications to contribute any Content or take any action(s) that 
        (or facilitate(s) or support(s) others in taking any action(s) that):
        </p>

        <ol type="a">
          <li>infringe, dilute, misappropriate or otherwise violate any of our privacy, intellectual property, publicity or other personal 
          rights, including, without limitation, copyrights, patents, trademarks, trade secrets or other proprietary information (including 
          unauthorized use of domain names);</li>

          <li>express or imply that any of your statements, activities or causes are endorsed by Unframed, XQ and/or the Initiative, without 
          our prior written consent in each instance;</li>
          <li>violate any applicable law, statute, ordinance or regulation, or encourage any conduct that could constitute a criminal offense 
          give rise to civil liability or otherwise facilitates, organizes, promotes or admits criminal or harmful activities targeted at 
          people, business, property or animals;</li>
          <li>constitute (i) engagement in any legislative lobbying, (ii) promotion or opposition to anyone’s candidacy in any election to public 
            office or to any political party, or (iii) the conduct of any voter registration drive (unless certain conditions are met). </li>
          <li>are fraudulent obscene, libelous, defamatory, threatening, harassing, degrading, mocking, shaming, invasive of privacy, abusive, 
            tortious, hateful, discriminatory, pornographic or advocates, incites or facilitates violence; </li>
          <li>encourage suicide or self-injury (including self-mutilation or eating disorders);</li>
          <li>depict, threaten or promote sexual violence, sexual assault, sexual exploitation or other exploitation of humans (including human 
            trafficking or any other activities against their will) or sexual activities or imagery;</li>
          <li>transmit any trade secret or other material, non-public information about any person, company or entity without the authorization to do so;</li>
          <li>bypass the measures we may use to prevent or restrict access to the Applications, including, without limitation, features 
            that prevent or restrict use or copying of any content or enforce limitations on use of the Applications or the content therein;</li>
          <li>modify, adapt, sub-license, translate, sell, reverse engineer, decompile or disassemble any portion of the Applications or 
            otherwise attempt to extract the source code of the software underlying the Applications or any part thereof unless expressly 
            permitted or required by law,and in any case, without providing prior written notice to Unframed;</li>
          <li>remove any copyright, trademark or other proprietary rights notices contained in or on the Applications;</li>
          <li>sublicense, sell, rent, lease, transfer, assign, or convey any rights under this Agreement to anythird party, 
            or otherwise commercially exploit or profit from the Applications, or any portion thereof, in any manner whatsoever, 
            except as expressly permitted herein;</li>
          <li>copy, “frame” or “mirror” any part of the Applications without our prior written authorization;</li>
          <li>distribute any virus, worm or other similar, malicious or deleterious files, scripts or programming routines 
            or do anything that could disable, overburden or impair the proper working or appearance of the Applications;</li>
          <li>involve the preparation or distribution of junk mail, spam, chain letters, pyramid schemes, or other deceptive or 
            unsolicited bulk or commercial email, or violate in any way the Controlling the Assault of Non-Solicited Pornography 
            and Marketing Act (CANSPAM Act of2003);</li>
          <li>deceive people to gain an unfair advantage, gather sensitive personal information or deprive another of money, property, or legal right;</li>
          <li>attempt to disrupt, degrade, impair or violate the integrity, functionality or security of the Applications or the computers, services, 
            or networks of any other party (including, without limitation, “hacking,” “denial of service” attacks, etc.);</li>
            <li>manipulate identifiers in order to disguise the origin of any submission;</li>
            <li>submit to the Applications or to Unframed any information that may be protected under the Health Insurance Portability and Accountability Act, 
            as amended and supplemented (“HIPAA”), or any other information that may be protected from disclosure by applicable law; </li>
            <li>use the Applications for any commercial solicitation purposes;</li>
            <li>use the Applications for any purpose that conflicts with any local, state, federal, or international laws and regulations;</li>
            <li>harvest or collect information about any Applications visitors or members, including any personally identifiable information, 
            without their express consent;</li>
            <li>purchase, sell or trade non-medical drugs, pharmaceutical drugs, marijuana, firearms or ammunition; and/or</li>
            <li>otherwise violates this Agreement or the Privacy Policy.</li>
        </ol>

          <p>
          Improper use of the Applications may result in termination of your access to and use of the Applications (exercisable 
          in Unframed’s sole discretion), and/or civil or criminal liabilities. Unframed may, in its sole discretion, also remove, 
          or restrict access to, content that is in violationof these provisions.
          </p>

        <p className="terms-header">OWNERSHIP</p>
        <p>
        The Applications are owned by XQ, Unframed and XQ’s and Unframed’s licensors (as applicable), and the Information and Services 
        (and any intellectual property and other rights relating thereto) are and will remain the property of XQ, Unframed and Unframed’s 
        and XQ’s licensors and suppliers (as applicable).  The Information and Services may be protected by U.S. and international copyright, 
        trademark, trade dress, and other intellectual property and unfair competition laws, and you acknowledge that these rights are valid and 
        enforceable.  You acknowledge that you do not acquire any ownership rights in the Applications, Services, or Information by using the 
        Applications or any Services or Information.
        </p>

        <p>
        The trademarks, logos and service marks displayed on the Applications (collectively, the “Trademarks”) are trademarks of Unframed, 
        XQ, XQ’s licensors and suppliers, and/or others.  Except for the PERMITTED USE OF SERVICES AND INFORMATION stated above, nothing 
        contained in this Agreement or the Applications should be construed as granting, by implication, estoppel or otherwise, any license 
        or right to use any Trademark(s) without the express written permission of Unframed, XQ, XQ’s licensors or suppliers, or the 
        third-party owner of any such Trademark, except as set forth in this Agreement.
        </p>

        <p className="terms-header">CLAIMS OF COPYRIGHT AND TRADEMARK INFRINGEMENT </p>

        <p>
        The Digital Millennium Copyright Act (the “DMCA”) provides recourse for copyright owners who believe that material appearing on 
        the Internet infringes their rights under U.S. copyright law.  If you believe in good faith that materials hosted by Unframed infringe 
        your copyright or trademark rights, you (or your agent) may send us a notice requesting that the material be removed, or access to 
        it blocked.  The notice must include the following information:  (a)a physical or electronic signature of a person authorized to act 
        on behalf of the owner of an exclusive right that is allegedly infringed; (b)identification of the copyrighted work or trademarkclaimed 
        to have been infringed (or if multiple copyrighted works located on the Applications are covered by a single notification, a representative 
        list of such works); (c)identification of the material that is claimed to be infringing or the subject of infringing activity, 
        and information reasonably sufficient to allow Unframed to locate the material on the Applications; (d)the name, address, telephone 
        number and email address (if available) of the complaining party; (e)a statement that the complaining party has a good-faith belief 
        that use of the material in the mannercomplained of is not authorized by the copyright or trademark owner, its agent or the law; 
        and (f)a statement that the information in the notification is accurate and, under penalty of perjury, that the complaining party 
        is authorized to act on behalf of the owner of an exclusive right that isallegedly infringed.  If Unframed determines that the materials 
        alleged to infringe your copyrightor trademark rights do not require removal, Unframed reserves the right to continue to display such 
        materials absent a court order declaring the content or use of the materials unlawful.
        </p>

        <p>
        If you believe in good faith that a notice of copyright infringement has been wrongly filed against you, the DMCA permits you to 
        send us a counter-notice.  Counter-notices must include the following information:  (a)your name, address and telephone number; 
        (b)the source of the content that was removed; (c)a statement under penalty of perjury that you have a good-faith belief that the 
        content was removed in error; (d)a statement that you consent to the jurisdiction of the federal district court for the judicial 
        district in which your address is located, or if your address is outside of the United States, for any judicial district in which 
        Unframed or XQ may befound, and that you will accept service of process from the person who provided the original complaint; and (e)a 
        physical or electronic signature (for example, typing your full name).
        </p>

        <p>
        Notices and counter-notices with respect to the Applications must meet the then-current statutoryrequirements imposed by the 
        DMCA (see http://www.copyright.gov/title17/92appb.htmlh for details) and should be sent to the agent identified below.  
        We suggest that you consult your legal advisor before filing a notice or counter-notice.  Also, be aware that there can be 
        penalties for false claims under the DMCA.
        </p>
        
        <p className="location">
        Unframed USA Ltd.<br/>11 Spring St.<br/>New York, NY 10012<br/>email: yearbook@insideoutproject.net
        </p>

        <p className="terms-header">TERM & TERMINATION</p>

        <p>
        This Agreement is effective from the date that you first access the Applications or submit any information to Unframed 
        through the Applications, whichever is earlier, and shall remain effective until terminated in accordance with its terms.  
        Unframed may immediately terminate this Agreement, and/or your access to and use of the Applications, or any portion thereof, 
        at any time and for any reason, with or without cause, without prior notice.  Unframed may also terminate this Agreement 
        immediately if you fail to comply with any term or provision of this Agreement.  Upon termination of this Agreement by either 
        party, your right to use the Applications shall immediately cease.  All disclaimers and all limitations of liability and all 
        XQ rights of ownership shall survive any termination.  We reserve the right at any time and from time to time to modify, discontinue, 
        temporarily or permanently, the Applications, or any part or portion thereof, with or without notice to you.  You agree that we shall 
        not be liable to you or to any third party for any modification, suspension or discontinuance of the Applications, or any part of 
        portion thereof.  Nothing in this Agreement shall be construed to obligate Unframed to maintain and support the Applications, 
        or any part or portion thereof, during the term of this Agreement.
        </p>

        <p className="terms-header">DISCLAIMERS</p>

        <p>
        THE APPLICATIONS, INCLUDING BUT NOT LIMITED TO THE INFORMATION AND SERVICES, ARE PROVIDED “AS IS” AND “WITH ALL FAULTS” AND 
        WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, AND ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, 
        IMPLIEDWARRANTIES OF TITLE, NON-INFRINGEMENT, ACCURACY, COMPLETENESS, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE.  
        ANY WARRANTIES THAT MAY ARISE FROM COURSE OF DEALING, COURSE OF PERFORMANCE OR USAGE OF TRADE, AND ANY WARRANTIES THAT THE 
        INFORMATION AND APPLICATIONS ARE CURRENT AND/OR UP TO DATE ARE HEREBY EXPRESSLY DISCLAIMED TO THE FULLEST EXTENT PERMISSIBLE UNDER 
        APPLICABLE LAW.
        </p>

        <p>
        THERE IS NO WARRANTY, REPRESENTATION OR GUARANTEE THAT THE APPLICATIONS, OR YOUR USE OF THE APPLICATIONS, WILL BE UNINTERRUPTED, 
        COMPLETE, ACCURATE, CURRENT, RELIABLE, ERROR-FREE, SECURE, OR THAT ANY PROBLEMS WILL BE CORRECTED, OR THAT THE APPLICATIONS, 
        OR ANY INFORMATION, SOFTWARE OR OTHER MATERIAL ACCESSIBLE FROM THE APPLICATIONS, ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. 
        WE DO NOT WARRANT, GUARANTEE OR MAKE ANY REPRESENTATION REGARDING THE USE OF, OR THE RESULTS OF THE USE OF, THE APPLICATIONS EITHER IN 
        TERMS OF ITS COMPATIBILITY WITH HARDWARE OR OTHER SOFTWARE OR EQUIPMENT, AND YOU ASSUME ALL RESPONSIBILITY AND RISK FOR YOUR USE OF THE 
        APPLICATIONS AND/OR SERVICES AND INFORMATION AND YOUR RELIANCE THEREON.
        </p>

        <p className="terms-header">LIMITATION OF LIABILITY</p>

        <p>
        NOTWITHSTANDING THE FAILURE OF ESSENTIAL PURPOSE OF ANY LIMITED REMEDY OF ANY KIND, NEITHER UNFRAMED, XQ NOR ANY OF ITS LICENSORS, 
        SPONSORS, AGENTS, SUCCESSORS, OR ASSIGNS, NOR OUR OR THEIR DIRECTORS, OFFICERS, EMPLOYEES, CONSULTANTS OR OTHER REPRESENTATIVES, 
        ARE RESPONSIBLE OR LIABLE FOR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, EXEMPLARY, PUNITIVE OR OTHER DAMAGES (INCLUDING 
        WITHOUT LIMITATION ANY LOSS OF PROFITS, LOST SAVINGS OR LOSS OF DATA) OR LIABILITIES UNDER ANY CONTRACT, NEGLIGENCE, STRICT LIABILITY, 
        OR OTHER THEORY ARISING OUT OF OR RELATING IN ANY  MANNER TO THE APPLICATIONS, INFORMATION, SERVICES AND/OR ANY LINKED WEBSITE, 
        WHETHER OR NOT WE HAVE BEEN INFORMED OF THE POSSIBILITY OF SUCH DAMAGES OR LIABILITIES.  YOUR SOLE REMEDY WITH RESPECT TO THE APPLICATIONS, 
        THE INFORMATION, SERVICES OR ANY LINKED WEBSITE IS TO STOP USING THE APPLICATIONS, SERVICE OR LINKED WEBSITE, AS APPLICABLE.  
        NEITHER UNFRAMED, XQ NOR ANY OF ITS LICENSORS, SPONSORS, AGENTS, SUCCESSORS OR ASSIGNS, NOR OUROR THEIR DIRECTORS, OFFICERS, EMPLOYEES, 
        CONSULTANTS OR OTHER REPRESENTATIVES WILL HAVE ANY LIABILITY TO YOU FOR ANY DAMAGES, EXPENSES OR OTHER LIABILITY INCURRED BY YOU AS A 
        RESULT OF ANY INACCURACY, INCOMPLETENESS OR MISREPRESENTATION OF ANY INFORMATION, CONTENT, POSTINGS OR SUBMISSIONS PROVIDED OR POSTED 
        ON THE APPLICATIONS BY THIRD PARTIES.
        </p>

        <p>
        WHEN INTERACTING WITH OTHER USERS, YOU SHOULD EXERCISE CAUTION ANDCOMMON SENSE TO PROTECT YOUR PERSONAL SAFETY AND PROPERTY, JUST AS YOU 
        WOULD WHEN INTERACTING WITH OTHER PERSONS WHOM YOU DO NOT KNOW.  NEITHER UNFRAMED, XQ, ITS OR THEIR AFFILIATES, LICENSORS NOR THEIR RESPECTIVE 
        AGENTS, DIRECTORS, OFFICERS, EMPLOYEES NOR ALL OTHER RELATED PERSONS OR ENTITIES ARE RESPONSIBLE FOR THE CONDUCT, WHETHER ONLINE OR OFFLINE, 
        OF ANY USER OF THE APPLICATIONS, AND YOU HEREBY RELEASE THE FOREGOING PARTIES FROM ANY LIABILITY RELATED THERETO.  UNFRAMED, XQ, ITS AFFILIATES, 
        LICENSORS AND THEIR RESPECTIVE AGENTS, DIRECTORS, OFFICERS, EMPLOYEES AND ALL OTHER RELATED PERSONSOR ENTITIES WILL NOT BE LIABLE FOR ANY 
        CLAIM, INJURY OR DAMAGE ARISINGIN CONNECTION WITH YOUR USE OF THE APPLICATIONS.
        </p>

        <p>
        IN THE EVENT THAT YOU HAVE A DISPUTE WITH ONE OR MORE OTHER USERS OF THE SITE (INCLUDING, WITHOUT LIMITATION, ANY DISPUTE BETWEEN USERS 
        REGARDING ANY TRANSACTION OR CONTENT) OR ANY THIRD-PARTY PROVIDER, YOU HEREBY AGREE TO RELEASE, REMISE AND FOREVER DISCHARGE UNFRAMED, 
        XQ, ITS AND THEIR AFFILIATES, LICENSORS AND THEIR RESPECTIVE AGENTS, DIRECTORS, OFFICERS, EMPLOYEES AND ALL OTHER RELATED PERSONSOR 
        ENTITIES FROM ANY AND ALL MANNER OF RIGHTS, CLAIMS, COMPLAINTS, DEMANDS, CAUSES OF ACTION, PROCEEDINGS, LIABILITIES, OBLIGATIONS, 
        LEGAL FEES, COSTS AND DISBURSEMENTS OF ANY NATURE WHATSOEVER, WHETHER KNOWN OR UNKNOWN, WHICH NOW OR HEREAFTER ARISE FROM, 
        RELATE TO, OR ARE CONNECTED WITH SUCH DISPUTE.
        </p>

        <p>
        SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF CERTAIN WARRANTIES AND/OR LIABILITIES, SO CERTAIN OF THE ABOVE LIMITATIONS 
        OR EXCLUSIONS MAY NOT APPLY TO YOU.
        </p>

        <p className="terms-header">NOTICE FOR CALIFORNIA USERS</p>

        <p>
        Under California Civil Code Section 1789.3, California Applications users are entitled to the following specific consumer rights notice:  
        The Complaint Assistance Unit of the Division of Consumer Services of the California Department of Consumer Affairs may be contacted 
        in writing at 1625 N. Market Blvd., Suite S-202, Sacramento, California 95834, or by telephone at (800) 952-5210.
        </p>

        <p className="terms-header">JURISDICTIONAL ISSUES</p>

        <p>
        Unframed makes no representation that the Applications operate (or are legally permitted to operate) in all geographic areas or that 
        the Applications are appropriate or available for use in other locations.  Accessing the Applications from territories where the 
        Applications or any content or functionality of the Applications or portion thereof is illegal is expressly prohibited.  
        If you choose to access the Applications, you agree and acknowledge that you do so on your own initiative and at your own 
        risk, and that you are solely responsible for compliance with all applicable laws.  The Applications are operated from the United 
        States.  If you are located outside of the United States and choose to use the Applications or provide your Content to Unframed and 
        XQ, your Content will be transferred, processed and stored in the United States.  U.S. privacy laws may not be as protective as those 
        in your jurisdiction.  Your agreement to the terms of this Agreement followed by your submission of your Content in connection with the 
        Applications represents your agreement to this practice.  If you do not want your Content transferred to or processed or stored in the 
        United States, you should not use the Applications.
        </p>

        <p className="terms-header">DISPUTE RESOLUTION/GOVERNING LAW</p>

        <p>
        This Agreement shall be governed by and construed in accordance with the laws of the State of California, excluding that body of law 
        relating to conflict of laws.  For any dispute you have withUnframed or XQ regarding or related in any way to the Initiative, you 
        agree to first contact Unframed and/or XQ (as applicable) and attempt to resolve the dispute with the applicable party informally.  
        If Unframed or XQ has not been able to resolve the dispute with you informally, we each agree that any controversy or claim arising out 
        of or relating to this Agreement, or the breach thereof, will be settled by binding arbitration by the American Arbitration Association 
        (“AAA”) under the Commercial Arbitration Rules and Supplementary Procedures for Consumer Related Disputes then in effect for the AAA, 
        except as provided herein.  The arbitration shall be a confidential proceeding, closed to the general public.  The arbitration will take 
        place in San Francisco, California.  Each party will be responsible for paying any AAA filing, administrative and arbitrator fees.  
        The decision rendered by the arbitrator will be binding upon the parties hereto, and any judgment upon the award rendered by the arbitrator 
        may be entered in any court having jurisdiction thereof.  The parties consent to the jurisdiction of all federal and state courts in 
        California without regard to California’s conflict of laws principles AND WILL SPECIFICALLY NOT BE GOVERNED BY THE UNITED NATIONS CONVENTIONS 
        ON CONTRACTS FOR THE INTERNATIONAL SALE OF GOODS, IF OTHERWISE APPLICABLE.
        </p>

        <p>
        Venue will lie exclusively in San Francisco, California.  For the sake of clarity, nothing in this paragraph shall affect Unframed’s or XQ’s 
        ability to seek from a court injunctive or equitable relief at any time.
        </p>

        <p>
        If any arbitration or other proceeding is brought to enforce or interpret this Agreement or matters relating to it, the 
        substantially prevailing party, as determined by the arbitrator’s award, will be entitled to recover reasonable attorneys’ 
        fees and other costs and expenses incurred in such arbitration or proceeding from the other party, in addition to any other relief 
        to which such prevailing party is entitled; provided that in no event will the arbitrator have the authority to award punitive damages.
        </p>

        <p>
        ALL CLAIMS MUST BE BROUGHT IN THE PARTIES’ INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR 
        REPRESENTATIVE PROCEEDING, AND, UNLESS UNFRAMED AND XQ AGREE OTHERWISE, THE ARBITRATOR MAY NOT CONSOLIDATE MORE THAN ONE PERSON’S CLAIMS.  
        YOU AGREE THAT, BY ENTERING INTO THESE TERMS, YOU, UNFRAMED AND XQ ARE EACH WAIVING THE RIGHT TO TRIAL BY JURY OR TO PARTICIPATE IN A CLASS 
        ACTION.
        </p>

        <p className="terms-header">MISCELLANEOUS</p>

        <p>
        If any provision of this Agreement is found to be unlawful, void, or for any reason unenforceable, then that provision shall be 
        deemed severable from this Agreement and shall not affect the validity and enforceability of any remaining provisions.  This is the 
        entire Agreement between you, XQ and us relating to the subject matter herein and supersedes any and all prior or contemporaneous 
        written or oral agreements between you, XQ and us with respect to such subject matter.  This Agreement may not be changed, waived or 
        modified except by Unframed asprovided herein or otherwise by written instrument signed by Unframed.  Neither this Agreementnor any 
        right, obligation or remedy hereunder is assignable, transferable, delegable or sublicensable by you except with Unframed’s and XQ’s prior 
        written consent, and any attempted assignment, transfer, delegation or sublicense shall be null and void.  Unframed or XQ may assign, 
        transfer or delegate this Agreement or any right or obligation or remedy hereunder in its sole discretion.  No waiver by either party of 
        any breach or default hereunder shall be deemed to be a waiver of any preceding or subsequent breach or default.  Any heading, caption or 
        section title contained in this Agreement is inserted only as a matter of convenience and in no way defines or explains any section or 
        provision hereof.
        </p>

        <p className="terms-header">THIS WEBSITE IS OPERATED BY</p>

        <p>
        Unframed USA Ltd.<br/>11 Spring St.<br/>New York, New York 10012<br/>Phone: (212) 431-3031<br/><br/>Updated as of May 8, 2020
        </p>

      </div>
    </div>
  );
}

export default Terms;
