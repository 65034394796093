import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import { Controller } from "react-hook-form";
import classNames from 'classnames';

export default function HighSchoolSelect({ className, onChange, control, formStyle }) {
  const [schools, setSchools] = useState([]);

  useEffect(() => {
    let highSchools = sessionStorage.getItem("highSchools");

    if (highSchools && JSON.parse(highSchools).length > 0) {
      let highSchoolsList: any = JSON.parse(highSchools);
      setSchools(highSchoolsList);
    } else {
      fetch("https://api.graduatetogetheryearbook.com/schools")
      .then(res => res.json())
      .then(
        (result) => {
          result.data.unshift({ 
            name: "Other",
            state: "Other",
            city: "Other"
          });
          setSchools(result.data);
          sessionStorage.setItem("highSchools", JSON.stringify(result.data));
        },
        (error) => {

        }
      )
    }
  }, [])



  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    limit: 10,
    stringify: (school: any) => school.name === "Other" ? "Other" : [school.name, school.city, school.state].join(", "),
  });

  return (
    <Controller
      as={
        <Autocomplete
          options={schools}
          limitTags={10}
          filterOptions={filterOptions}
          getOptionLabel={school => school.name === "Other" ? "Other" : [school.name, school.city, school.state].join(", ")}
          defaultValue={{}}
          renderInput={params => (
            <TextField
              {...params}
              variant={formStyle}
              fullWidth={true}
              placeholder="Start typing to find your High School"
            />
          )}
          renderOption={school => (
            <span>
              {school.name === "Other" ? "Other" : [school.name, school.city, school.state].join(", ")}
            </span>
          )}
        />
      }
      onChange={([, data]) => data}
      name="school_name"
      rules={{
        validate: value => {
          return !!value;
        },
        required: 'Required field'
      }}
      className={classNames('form-input-field', {
        className: className,
      })}
      control={control}
      defaultValue={null}
    />
  );
}
