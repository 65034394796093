import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import { Autocomplete, createFilterOptions } from '@material-ui/lab';

export default function HighSchoolSelect({ className, onChange, control, formStyle, reset }) {
  const [schools, setSchools] = useState([]);

  useEffect(() => {
    let highSchools = sessionStorage.getItem("highSchools");

    if (highSchools && JSON.parse(highSchools).length > 0) {
      let highSchoolsList: any = JSON.parse(highSchools);
      setSchools(highSchoolsList);
    } else {
      fetch("https://api.graduatetogetheryearbook.com/schools")
      .then(res => res.json())
      .then(
        (result) => {
          setSchools(result.data);
          sessionStorage.setItem("highSchools", JSON.stringify(result.data));
        },
        (error) => {

        }
      )
    }
  }, [])



  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    limit: 10,
    stringify: (school: any) => school.name === "Other" ? ["Other", school.city, school.state].join(", ") : [school.name, school.city, school.state].join(", "),
  });

  return (
    <Autocomplete
      options={schools}
      limitTags={10}
      filterOptions={filterOptions}
      getOptionLabel={school => school.name === "Other" ? ["Other", school.city, school.state].join(", ") : [school.name, school.city, school.state].join(", ")}
      defaultValue={null}
      onChange={onChange}
      key={reset}
      renderInput={params => (
        <TextField
          {...params}
          variant={formStyle}
          fullWidth={true}
          placeholder="Start typing school name"
        />
      )}
      renderOption={school => (
        <span>
          {school.name === "Other" ? ["Other", school.city, school.state].join(", ") : [school.name, school.city, school.state].join(", ")}
        </span>
      )}
    />
  );
}
