import React from 'react';

const NotFound = () => {
  return (
    <div className="home-container">
      <div className="headline">
        <div className="headline-content">
          <h1>Oops, that page doesn't exist!</h1>
        </div>
      </div>
    </div>
  );
}

export default NotFound;
